import React from 'react';
import { MODULE_PATH } from '../constants/Module';
import { Navigate } from 'react-router-dom';
import DottedLoader from './DottedLoader';
import LandingPage from './v2/LandingPage';

const RedirectToLoaderAndLandingPage = ({ shouldRedirect, showLoader }) => {
  if (shouldRedirect === MODULE_PATH.WELCOME) {
    return showLoader ? <DottedLoader /> : <LandingPage />;
  }
  return <Navigate to={shouldRedirect} replace />;
};

export default RedirectToLoaderAndLandingPage;
