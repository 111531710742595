import React, { useEffect } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ChevronRight from '../../../../shared/assets/svg/my-accounts/chevronRight.svg';

import { V4_ICONS } from '../../../../shared/utils/enums/AppIconsV4';

import { MODULE_PATH } from '../../../../shared/constants/Module';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { setFormTab } from '../../../../shared/redux/home/actions';
import {
  formatPhoneNumber,
  capitalizeName,
  isEmpty,
} from '../../../../shared/utils/helpers/purefunctions';

import {
  fetchReferralOffer,
  fetchReferralCode,
  fetchReferralRedemptions,
  setFetched,
} from '../../../../shared/redux/referral/action';
import { ZONE } from '../../../../shared/utils/enums/AppConstants';
import Footer from '../../v4/Home/components/Footer';

const MyAccountsInfo = () => {
  const { MY_ACCOUNTS, ADDRESS_BOOK } = MODULE_PATH;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const prevNavigate = {
    label: '',
    imgPath: '',
    path: MODULE_PATH.HOME,
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const user = useSelector(state => state.usersReducer.currentUser);
  const { fetched, referralCode } = useSelector(state => state.referralReducer);
  const name = `${user.firstName} ${user.lastName}`;
  const { appInfo } = useSelector(state => state.appAccessReducer);

  useEffect(() => {
    if (!fetched) {
      const actionFunctions = [fetchReferralOffer, fetchReferralCode, fetchReferralRedemptions];

      const promises = actionFunctions.map(actionFunction => dispatch(actionFunction(user.id)));

      Promise.all(promises).then(() => {
        dispatch(setFetched(true));
      });
    }
  }, [fetched, user.id, dispatch]);

  const goToAddressBook = () => {
    dispatch(setFormTab('address-book'));
    navigate(ADDRESS_BOOK.MAIN);
  };

  const goToTermsAndConditions = () => navigate(MY_ACCOUNTS.TERMS_CONDITIONS);
  const goToReferrrals = () => navigate(MY_ACCOUNTS.REFERRALS);
  const goToPrivacyPolicy = () => navigate(MY_ACCOUNTS.PRIVACY_POLICY);
  const goToAboutUs = () => navigate(MY_ACCOUNTS.ABOUT_US);

  const getInitial = name => {
    return name.charAt(0);
  };

  const initial = () => {
    return `${getInitial(user?.firstName)}${getInitial(user?.lastName)}`;
  };

  const prepReferralCode = () => {
    return message.info('Generating code. Please wait.', 3.0);
  };

  const hasCode = fetched && !isEmpty(referralCode);
  const env = ZONE.ENV === 'STAGING' ? 's' : ZONE.ENV === 'PRE-PRODUCTION' ? 'p' : '';
  return (
    <>
      <div className="my-accounts-info-container">
        <StickyHeader
          title=""
          onPrevNavigate={prevNavigate}
          callback={() => dispatch(setFormTab('onDemand'))}
        />
        {!isEmpty(user) && (
          <div className="card-info">
            <div className="profile-avatar hdln-area">
              <span className="initial">{initial()}</span>
            </div>
            <div className="profile-info">
              <span className="name hdln-block">{capitalizeName(name)}</span>
              <span className="contact cntnt-caption">{formatPhoneNumber(user.gcashNumber)}</span>
              <span className="email cntnt-caption">{user.email}</span>
            </div>
          </div>
        )}
        <div className="wrapper-rewards-referral">
          <div className="card-tab-info">
            <div className="rows">
              <img src={V4_ICONS.REWARDS} className="icon" alt="right arrow" />
              <span className="title cntnt-base">Rewards</span>
              <img src={V4_ICONS.SOON} className="next-info" alt="arrow right" />
            </div>
          </div>
          <div className="card-tab-info">
            <div
              className={`rows has-referral-code`}
              onClick={hasCode ? goToReferrrals : prepReferralCode}
            >
              <img src={V4_ICONS.REFERRAL} className="icon" alt="right arrow" />
              <span className="title cntnt-base">Referrals</span>
              <img src={ChevronRight} className="next-info" alt="arrow right" />
            </div>
          </div>
        </div>

        <div className="card-info">
          <div className="rows" onClick={goToAddressBook}>
            <img src={V4_ICONS.ADDRESSBOOK} className="icon" alt="right arrow" />
            <span className="title cntnt-base">Address Book</span>
            <img src={ChevronRight} className="next-info" alt="arrow right" />
          </div>
          <div className="rows" onClick={goToTermsAndConditions}>
            <img src={V4_ICONS.TNC} className="icon" alt="terms and conditions" />
            <span className="title cntnt-base">Terms And Conditions</span>
            <img src={ChevronRight} className="next-info" alt="arrow right" />
          </div>
          <div className="rows" onClick={goToPrivacyPolicy}>
            <img src={V4_ICONS.POLICY} className="icon" alt="privacy policy" />
            <span className="title cntnt-base">Privacy Policy</span>
            <img src={ChevronRight} className="next-info" alt="arrow right" />
          </div>
          {/* <div className="rows" onClick={goToAboutUs}>
          <img src={AboutUs} className="icon" alt="about us" />
          <span className="title lbl-base">About Us</span>
          <img src={ChevronRight} className="next-info" alt="arrow right" />
        </div> */}
        </div>
      </div>

      <div className="my-account-container">
        <div className="my-account-content">
          <div className="body-content">
            <div className="parcels-logo-container">
              <img src={V4_ICONS.PARCELS} alt="Parcels logo" className="parcels-logo" />
              <div className="version cntnt-caption-bold">
                {appInfo?.version || 'v3.0.140 (1.0.140) Build; Build 1.0.140'}{' '}
                {`${env ? ` -  (${env})` : ''}`}
              </div>
              <div className="powered-by">
                <span className="cntnt-fine-caption">Powered by </span>
                <img className="lmi-powered" src={V4_ICONS.LMI} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyAccountsInfo;
