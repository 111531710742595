export const STAGING_USERS = {
  LEGAL: {
    email: 'legalbasic122@gmail.com',
    firstName: 'LEGAL',
    gcashNumber: '09175000003',
    id: '110700400000000000648635054',
    lastName: 'BASIC',
  },
  BILLSPAY: {
    email: 'lizllante@gmail.com',
    firstName: 'BILLSPAY',
    gcashNumber: '09056628913',
    id: '110700400000000000647412367',
    lastName: 'UAT TESTING',
  },
  TESTING_NAME: {
    email: 'Paymongo@QC.com',
    firstName: 'SAMPLE',
    gcashNumber: '09164635365',
    id: '110700400000000000646444770',
    lastName: 'TESTINGNAME',
  },
  CELO: {
    email: 'mlca292691@gmail.com',
    firstName: 'MARCELO',
    gcashNumber: '09222069608',
    id: '110700400000000000648291709',
    lastName: 'CANTONG II',
  },
  CAMILLE: {
    email: 'chielongay920@gmail.com',
    firstName: 'CAMILLE',
    gcashNumber: '09190029844',
    // id: "110700400000000000137813573", //prod
    id: '110700400000000000648362809', //sit
    lastName: 'LONGAY',
  },
  BORDS: {
    email: 'martbords07@gmail.com',
    firstName: 'REYMART',
    gcashNumber: '09502986747',
    id: '110700400000000000133933692',
    lastName: 'BORDO',
  },
  MARY_GRACE: {
    email: 'mgocampo21@gmail.com',
    firstName: 'MARY GRACE',
    lastName: 'OCAMPO',
    gcashNumber: '09177930363',
    id: '110700400000000000648362809',
  },
};

export const PROD_USERS = {
  BILLSPAY: {
    email: 'lizllante@gmail.com',
    firstName: 'BILLSPAY',
    gcashNumber: '09056628913',
    id: '110700400000000000647412367',
    lastName: 'UAT TESTING',
  },
  MARY_GRACE: {
    email: 'mgocampo21@gmail.com',
    firstName: 'MARY GRACE',
    lastName: 'OCAMPO',
    gcashNumber: '09177930363',
    id: '110700400000000000106238155',
  },
  ALLYN: {
    email: 'lynmidel@getMaxListeners.com',
    firstName: 'ALLYN',
    lastName: 'MIDEL',
    id: '110700400000000000388884073',
    gcashNumber: '09171098343',
  },
  BORDS: {
    email: 'martbords07@gmail.com',
    firstName: 'REYMART',
    gcashNumber: '09502986747',
    id: '110700400000000000133933692',
    lastName: 'BORDO',
  },
  CAMILLE: {
    email: 'chielongay920@gmail.com',
    firstName: 'CAMILLE',
    gcashNumber: '09190029844',
    id: '110700400000000000137813573',
    lastName: 'LONGAY',
  },
  TEST: {
    email: 'test@gmail.com',
    firstName: 'TE',
    lastName: 'ST',
    gcashNumber: '09123456789',
    id: '110700400000000000105199847',
  },
};
