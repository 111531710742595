import React from 'react';
import CourierList from '../CourierList';

const SimilarCouriers = ({ orderType, title, isReselect, isDrawer=false, currentCourierSelected }) => {
  return (
    <div className={`couriers-drawer-container ${isDrawer ? 'drawer': ''}`}>
      <span className="title hdln-block">{title}</span>
      <CourierList orderType={orderType} isDrawer={true} isReselect={isReselect} currentCourierSelected={currentCourierSelected} />
    </div>
  );
};

export default SimilarCouriers;
