import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENUM } from '../../../../../shared/utils/enums/v4/AppConstants';
import {
  addFieldFromArray,
  filterByName,
  removeProperties,
} from '../../../../../shared/utils/helpers/purefunctions';
import { ICON } from '../../../../../shared/utils/enums/v4/AppIcon';
import { Switch } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { BOOK_ANY } from '../../../../../shared/utils/enums/AppConstants';
import { setSelectedCourierInfo } from '../../../../../shared/redux/home/actions';
import { setCourier as setOnDemandCourier } from '../../../../../shared/redux/onDemand/actions';
import { setCourier as setStandardCourier } from '../../../../../shared/redux/standard/actions';
import { formatBills, isEmpty } from '../../../../../shared/utils/helpers/purefunctions';
import { COURIER_VEHICLES, FOOD_PANDA, PANDAGO } from '../../../../../shared/utils/enums/Providers';

const CourierRates = ({ couriersQouteData, isOnDemand }) => {
  const dispatch = useDispatch();
  const [isBookAny, setIsBookAny] = useState(false);
  const [availCouriers, showAvailCouriers] = useState(true);

  const { formTab, selectedCourierInfo } = useSelector(state => state.homeReducer);

  const isStandard = formTab.toLowerCase().includes('standard');

  const groupCouriersLogo = Object.groupBy(
    ENUM.COURIERS_LIST.couriers_logo,
    courier => courier.label
  );

  const selectedCourierRates = Array.isArray(groupCouriersLogo[selectedCourierInfo.label])
    ? groupCouriersLogo[selectedCourierInfo.label][0]
    : [];
console.log(couriersQouteData, selectedCourierRates.label)
  const currentRate = filterByName(couriersQouteData, (selectedCourierRates.label).toUpperCase() === PANDAGO.toUpperCase() ? FOOD_PANDA : selectedCourierRates.label);

  const availableCourierRates = removeProperties(
    groupCouriersLogo,
    [selectedCourierInfo.label, 'All'],
    selectedCourierInfo.type
  );

  useEffect(() => {
    console.log('pasok1: ', selectedCourierRates, currentRate)
    console.log('pasok2: ', isOnDemand)
    if (selectedCourierRates && currentRate) {
      if (isOnDemand) {
        dispatch(
          setOnDemandCourier({
            courier: isBookAny ? BOOK_ANY : (selectedCourierRates.label).toUpperCase() === PANDAGO.toUpperCase() ? FOOD_PANDA : selectedCourierRates.label,
            rate: currentRate?.rate || 0,
            vehicleType: currentRate?.vehicleType,
            error: currentRate?.error || null,
          })
        );
      } else {
        dispatch(
          setStandardCourier({
            courier: selectedCourierRates.label,
            rate: currentRate?.rate || 0,
            productSize: currentRate?.productSize,
            error: currentRate?.error || null,
          })
        );
      }
    }
  }, [dispatch, isBookAny, selectedCourierRates]);

  const hadleBookAnySwitch = boolean => {
    setIsBookAny(boolean);
  };

  const handleSelectAvailableCourier = courierInfo => {
    dispatch(setSelectedCourierInfo(courierInfo));
  };
  const CourierRate = ({ details }) => {
    const courierRateDetails = useMemo(() => {
      return addFieldFromArray(details, couriersQouteData, ['rate', 'success']).filter(
        data => data.success === true
      );
    }, [details, couriersQouteData]);

    isEmpty(courierRateDetails) && showAvailCouriers(false);

    return courierRateDetails.map(info => {
      return (
        <div
          className="rate-description"
          key={info.label}
          onClick={() => handleSelectAvailableCourier(info)}
        >
          <div className="description">
            <img src={info.logo} alt={info.label} />
            <span className='cntnt-base-bold'>{info.label}</span>
          </div>
          <div className="total-rate">
            <img src={ICON.PESO_SIGN} alt="Peso sign" />
            <span className='hdln-segment'>{formatBills(info.rate)}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="courier__rates__container">
      <div className="selected-courier">
        <div className="description">
          <img src={selectedCourierRates.logo} alt={selectedCourierRates.label} />
          <span className='cntnt-large-bold'>{selectedCourierRates.label} Total</span>
        </div>
        <div className="total-rate">
          <img src={ICON.PESO_SIGN} alt="Peso sign" />
          <span className='hdln-section'>{formatBills(currentRate?.rate)}</span>
        </div>
      </div>
      {availCouriers && (
        <div className="available-couriers">
          {availableCourierRates && <CourierRate details={availableCourierRates} />}
          {!isStandard && (
            <div className="book-any">
              <Switch
                size={'large'}
                unCheckedChildren={<MinusOutlined />}
                onChange={e => hadleBookAnySwitch(e)}
                className={`${isBookAny ? 'checked' : 'unchecked'}`}
              />
              <span>{ENUM.FORM.book_any_description}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(CourierRates);
