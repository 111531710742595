import { useCallback, useState } from 'react';

import { createOrderPayload } from '../utils/helpers/purefunctions';
import DPHDAO from '../utils/dao/DPH';
import { message } from 'antd';

const useCreateBooking = () => {
  const [bookingResult, setBookingResult] = useState(null);
  const [bookingError, setBookingError] = useState(null);

  const createBooking = useCallback(async (orderDetails, userInfo) => {
    try {
      const { orderPayload, courierPayload } = createOrderPayload(orderDetails, userInfo);
      const response = await DPHDAO.createOrder(orderPayload, courierPayload);
      setBookingResult(response);
    } catch (err) {
      setBookingError(err);
      message.info('Error creating your booking.');
      console.log('Error booking:', err);
    }
  }, []);

  return { createBooking, bookingResult, bookingError };
};

export default useCreateBooking;
