import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLoader } from '../../../shared/redux/home/actions';
import { setCurrentUser } from '../../../shared/redux/users/actions';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import OnDemandDAO from '../../../shared/utils/dao/DPH';
import AddressBookDAO from '../../../shared/utils/dao/AddressBook';
import {
  createOrderPayload,
  isEmpty,
  getConvenienceFee,
  formatPostOrder,
} from '../../../shared/utils/helpers/purefunctions';
import { MODULE_PATH } from '../../../shared/constants/Module';
import { message } from 'antd';
import {
  BOOK_ANY,
  COURIER,
  DEFAULT_REASON,
  DELIVERY_TYPE_VIEW,
  DEPLOYMENT,
  STAGING_AND_PRE_PROD_ENV,
  ZONE,
} from '../../../shared/utils/enums/AppConstants';
import PlacingOrder from '../../../shared/components/v2/PlacingOrder';
import Login from '../../../shared/utils/dao/Login';

import { setOnDemandInitialState } from '../../../shared/redux/onDemand/actions';
import { setStandardInitialState } from '../../../shared/redux/standard/actions';
import { CONVENIENCE_FEE } from '../../../shared/utils/enums/DeliveryConstants';
import { API_CONFIG, IS_LIVE, SHOW_DEBUG_TOAST, DEBUG_MODE } from '../../../shared/config/app';
import useCreateBooking from '../../../shared/hooks/useCreateBooking';

const PaymentProcess = ({
  userInfo,
  onDemandProps,
  standardProps,
  recentlyAddress,
  view,
  setOnDemandCourier,
  setCurrentUser,
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [happyPath, setHappyPath] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingCreated, setBookingCreated] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isCalledCreatedBooking, setIsCalledCreatedBooking] = useState(false);

  const formTab = useSelector(state => state.homeReducer.formTab);
  const referral = useSelector(state => state.referralReducer);
  const paymentReqId = location?.state?.gcash?.paymentRequestId;
  const refNo = location?.state?.refNo;

  const isStandard = formTab.toLowerCase().includes('standard');

  const paymentStatusPagePath = isStandard
    ? MODULE_PATH.PAYMENT.STANDARD.MAIN
    : MODULE_PATH.PAYMENT.ON_DEMAND.MAIN;

  const postOrder = formatPostOrder(onDemandProps, standardProps, isStandard, userInfo);
  const { createBooking, bookingResult, bookingError } = useCreateBooking();

  useEffect(() => {
    if (DEBUG_MODE) {
      createBooking(postOrder, userInfo);
      console.log('booking result', bookingResult);
      navigate(paymentStatusPagePath, {
        state: {
          status: bookingResult?.result?.success,
        },
      });
    } else {
      intervalInquiry();
    }
  }, [bookingCreated, bookingResult]);

  useEffect(() => {
    if (paymentStatus === 'SUCCESS') {
      setIsPaymentSuccess(true);
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (location?.state?.useCashBack && isPaymentSuccess) {
      const { orderId, cashbackPaymentDetails } = onDemandProps;
      const payload = {
        userId: userInfo.currentUser.id || '',
        paymentAmount: cashbackPaymentDetails?.cashbackDeduction || 0,
        refNo: orderId,
      };

      (async () => {
        const paymentDao = new PaymentDAO();
        payload.paymentAmount > 0 && (await paymentDao.getCashBack(payload));
      })();

      setIsPaymentSuccess(false);
    }
  }, [isPaymentSuccess]);

  const intervalInquiry = async () => {
    if (happyPath && bookingCreated) {
      return navigate(paymentStatusPagePath, {
        state: {
          status: bookingCreated,
        },
      });
    }

    const paymentDao = new PaymentDAO();
    const loginDao = new Login();

    const inquiry = await paymentDao.gcashPaymentInquiry({
      userId: userInfo.currentUser.id,
      paymentRequestId: paymentReqId,
    });

    SHOW_DEBUG_TOAST && message.info(`inquiry - ${JSON.stringify(inquiry)}`);

    if (inquiry) {
      let courierMode = 'regular';
      let advancedCourierMode = false;

      const statusPayment = inquiry?.result?.paymentStatus || 'INITIATED';

      SHOW_DEBUG_TOAST && message.info(`status payment ${JSON.stringify(statusPayment)}`);

      const postOrderDetails = {
        ...postOrder,
        gcash: {
          ...postOrder.gcash,
          ...location?.state?.gcash,
          status: statusPayment,
          referralCode: referral?.referralCode,
          referrerUserId: referral?.referrerUserId,
        },
      };

      /*
          update user redux regarless if the booking payment is success or not
        */

      await loginDao.updateUsersInfo({
        userId: userInfo.currentUser.id,
        fieldName: 'userOrderTags',
        fieldValue: JSON.stringify({
          ...userInfo.userOrderTags,
          advancedCourierMode,
          recordCreated: true,
          courierMode,
        }),
      });

      const { gcash, userId } = postOrder;

      SHOW_DEBUG_TOAST && message.info(`postOrder ${JSON.stringify(postOrderDetails)}`);

      if (statusPayment === 'SUCCESS') {
        SHOW_DEBUG_TOAST && message.info(`status payment - ${JSON.stringify(statusPayment)}`);

        if (!isCalledCreatedBooking) {
          await createBooking(postOrderDetails, userInfo);
          setIsCalledCreatedBooking(true);
        }

        const isOrderCreated = Boolean(bookingResult?.result?.success || bookingResult?.success);

        SHOW_DEBUG_TOAST && message.info(`isOrderCreated ${JSON.stringify(isOrderCreated)}`);

        let isCalledCasback = false;

        if (location?.state?.useCashBack && !isCalledCasback) {
          const payload = {
            userId: userInfo.currentUser.id || '',
            paymentAmount: gcash?.cashbackPaymentDetails?.cashbackDeduction || 0,
            refNo: onDemandProps?.orderId,
          };
          const paymentDao = new PaymentDAO();
          payload.paymentAmount > 0 && (await paymentDao.getCashBack(payload));
          isCalledCasback = true;
        }

        if (isOrderCreated) {
          const addressBook = onDemandProps.pickUp.senderDetails?.addressBook || {};
          /*
                update userInfo ( redux and real-time database)
                IF
                  User
                    No booking at all
                AND
                  Booking is On-Demand
                    previous booking courier mode and current booking courier mode is not the same
              */
          const isTriedBookedAny = onDemandProps?.courier?.courier === BOOK_ANY;
          const userInfoBookedAny = userInfo?.currentUser?.advancedCourierMode;

          const shouldUpdateUser = ({ currentUser }, isStandard) => {
            return (
              !currentUser?.recordCreated || (!isStandard && isTriedBookedAny !== userInfoBookedAny)
            );
          };

          if (shouldUpdateUser(userInfo, isStandard)) {
            const updatedUserInfo = {
              advancedCourierMode: isTriedBookedAny || userInfoBookedAny,
              recordCreated: true,
              courierMode: isTriedBookedAny ? 'advanced' : 'regular',
              userType: 'CURRENT',
            };

            await loginDao.updateUsersInfo({
              userId: userInfo.currentUser.id,
              fieldName: 'userOrderTags',
              fieldValue: JSON.stringify(updatedUserInfo),
            });
          }

          if (!isEmpty(addressBook)) {
            const addressDao = new AddressBookDAO();

            addressDao.editAddress(addressBook.id, userInfo.currentUser.id, {
              ...addressBook,
              recently: true,
            });

            !isEmpty(recentlyAddress) &&
              addressDao.editAddress(recentlyAddress.id, userInfo.currentUser.id, {
                ...recentlyAddress,
                recently: false,
              });
          }

          setHappyPath(true);
          setBookingCreated(isOrderCreated);
          setPaymentStatus(statusPayment);

          dispatch(setOnDemandInitialState());
          dispatch(setStandardInitialState());

          return navigate(paymentStatusPagePath, {
            state: {
              status: isOrderCreated,
              isFoodPandaCourier: onDemandProps?.courier?.courier === COURIER.PANDAGO,
            },
          });
        } else {
          await paymentDao.refund({
            paymentId: gcash?.paymentId || '',
            orderNo: refNo,
            paymentRequestId: gcash?.paymentRequestId || '',
            refundAmount: {
              currency: gcash?.paymentAmount.currency,
              value: gcash?.paymentAmount.value,
            },
            userId,
            refundReason: 'The user cancels the order due to some reason.',
          });

          /*
                save to mongoDB for refund purposes
              */
          cancelledTransaction(statusPayment, {
            userId: userInfo.currentUser.id,
            orderDetails: JSON.stringify({
              ...postOrder,
              gcash: {
                ...postOrder.gcash,
                extendInfo: JSON.stringify(booking?.message || ''),
              },
            }),
            status: statusPayment,
          });
        }
      } else if (statusPayment === 'FAIL' || statusPayment === 'CANCELLED') {
        /*
              save to mongoDB
            */
        cancelledTransaction(statusPayment, {
          userId: userInfo.currentUser.id,
          orderDetails: JSON.stringify(postOrder),
          status: statusPayment,
        });
      } else {
        await new Promise(resolve => setTimeout(resolve, 2500));
        intervalInquiry();
      }
    }
  };

  const updateUserInfo = async (userId, user) => {
    const updatedUser = {
      ...userInfo.currentUser,
      ...user,
    };
    setCurrentUser(updatedUser);
    const loginDao = new Login();
    await loginDao.updateUser(userId, updatedUser);
  };

  const cancelledTransaction = async (paymentStatus, order) => {
    const PaymentDao = new PaymentDAO();
    const transaction = await PaymentDao.pushTransaction(order);

    const user = userInfo.currentUser;
    const cancelledReason = `Failed payment - status: ${paymentStatus}`;

    const { gcash } = order;

    await PaymentDao.refund({
      postId: order?.postId || null,
      mdbId: order?.mdbId || null,
      orderNo: order.refNo,
      paymentId: gcash?.paymentId || '',
      paymentRequestId: gcash?.paymentRequestId || '',
      refundAmount: gcash?.paymentAmount || 0,
      contactNumber: user.gcashNumber,
      name: `${user.firstName} ${user.lastName}`,
      userId: user.id,
      refundReason: cancelledReason || DEFAULT_REASON,
    });

    if (transaction) {
      setHappyPath(true);
      setBookingCreated(true);
      setPaymentStatus(paymentStatus);

      dispatch(setOnDemandInitialState());
      dispatch(setStandardInitialState());

      return navigate(paymentStatusPagePath, {
        state: {
          status: bookingCreated,
        },
      });
    }
  };
  return <PlacingOrder />;
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
      setCurrentUser,
    },
    dispatch
  );
}
const mapStateToProps = state => ({
  onDemandProps: state.onDemandReducer,
  standardProps: state.standardReducer,
  onDemandPaymentRequestId: state.onDemandReducer.paymentRequestId,
  standardPaymentRequestId: state.standardReducer.paymentRequestId,
  userInfo: state.usersReducer,
  recentlyAddress: state.addressReducer.recentlyAddress,
  view: state.homeReducer.view,
});
export default connect(mapStateToProps, matchDispatchToProps)(PaymentProcess);
