import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Skeleton } from 'antd';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import OrdersCard from '../../../../../shared/components/v3/OrdersCard';
import DEF_ONGOING from '../../../../../shared/v4/assets/svg/default/OngoingOrders.svg';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import DeliveryTo from '../../../Home/v3/components/DeliveryTo';
import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';

const OngoingOrders = ({ allDone }) => {
  const user = useSelector(state => state.usersReducer);
  const { currentUser, userOrderTags } = user;

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);
  const { orderList, cancelledOrders, completedOrderList, formTab } = useSelector(
    state => state.homeReducer
  );

  const isOndemand = formTab.toLowerCase().includes('ondemand');

  const hasDropOffAddress = isOndemand
    ? onDemandProps.dropOff?.address
    : standardProps.dropOff?.address;

  const handleNavigateAllOrder = () => {
    if (userOrderTags.recordCreated) {
      navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS, { state: { path: MODULE_PATH.HOME } });
    }
  };

  const onRecentlyUsedAll = () => {
    return navigate(MODULE_ADDRESS, {
      state: { showAutoComplete: true, isRecentlyUsed: true, orders: latestCreatedRecords },
    });
  };
  const SkeletonLoaders = ({ count }) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <Card
          loading={true}
          style={{
            minWidth: 250,
          }}
        ></Card>
      ))}
    </>
  );

  const LoadingRecentlyOngoing = () => {
    return (
      <div className="orders flex-col">
        <div className="title flex-row items-center gap-16px">
          <span className="hdln-block">
            <Skeleton.Input block={true} active={true} size={'small'} style={{ width: '220px' }} />
          </span>
          <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
        </div>
        <div className="card-loading-wrapper">
          <SkeletonLoaders count={3} />
        </div>
      </div>
    );
  };

  const HandleOngoingOrderList = () => {
    if (userOrderTags === 'NEW') {
      return (
        <div className="orders-card">
          <div className="title flex-row items-center gap-16px def">
            <span className="lbl-base">Ongoing Orders</span>
            <img src={ICON.ARROW_RIGHT} alt="arrow right" />
          </div>
          <img
            className="def-orders parcel-banner"
            src={DEF_ONGOING}
            alt="Default Ongoing Orders"
          />
        </div>
      );
    }
    if (!allDone) {
      return <LoadingRecentlyOngoing />;
    } else {
      if (soonestCompletionDates.length) {
        return (
          <div className="flex-col">
            <div className="title flex-row items-center gap-16px">
              <span className="lbl-base">Ongoing Orders</span>
              <img src={ICON.ARROW_RIGHT} alt="arrow right" onClick={handleNavigateAllOrder} />
            </div>
            <div className="orders-card v4">
              <OrdersCard isOngoing={true} orders={soonestCompletionDates} />
            </div>
          </div>
        );
      } else {
        if (latestCreatedRecords.length) {
          return (
            <div className="flex-col">
              <div className="title flex-row items-center gap-16px">
                <span className="lbl-base">Recently Used</span>
                {/* <img src={ICON.ARROW_RIGHT} alt="arrow right" /> */}
              </div>
              <DeliveryTo orders={latestCreatedRecords} />
            </div>
          );
        }
      }
    }
  };

  const _orderList = [...orderList];
  const soonestCompletionDates = _orderList
    .sort((a, b) => a.deliveryDetails.completionDateTime - b.deliveryDetails.completionDateTime)
    .slice(0, 3);

  const recentlyOrders = !isEmpty(_orderList)
    ? _orderList
    : !isEmpty(completedOrderList)
    ? completedOrderList
    : cancelledOrders;

  const latestCreatedRecords = [...recentlyOrders]
    .sort((a, b) => a.createdAt - b.createdAt)
    .slice(0, 10);

  return (
    <div className="ongoing-order-wrapper flex-col">
      <HandleOngoingOrderList />
    </div>
  );
};

export default memo(OngoingOrders);
