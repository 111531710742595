import React, { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { ENUM } from '../../../../shared/utils/enums/v4/AppConstants';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { useNavigate } from 'react-router';
import CourierList from '../Home/components/CourierList';
import VoucherLists from '../Home/components/VoucherLists';
import { useDispatch, useSelector } from 'react-redux';
import FieldSelection from './components/FieldSelection';
import CourierRates from './components/CourierRates';
import { COURIER_VEHICLE_TYPES } from '../../../../shared/utils/enums/Providers';
import axios from 'axios';
import {
  COURIER_PRODUCT_SIZES,
  STANDARD_COURIERS,
} from '../../../../shared/utils/enums/StandardDelivery';
import IconItemDetails from '../../../../shared/assets/svg/home-booking/icon_item-details.svg';
import ItemDetailsList from '../../../../shared/components/ItemDetailsList';
import { ON_DEMAND_ICON, STANDARD_ICON } from '../../../../shared/utils/enums/AppIcons';

import {
  Ellips,
  capitalizeName,
  formatUnderscoredText,
  generateOrderId,
  getStandardCourierQoute,
  isEmpty,
} from '../../../../shared/utils/helpers/purefunctions';
import startMapMarker from '../../../../shared/assets/images/OndemandDelivery/start_point_location.svg';
import endMapMarker from '../../../../shared/assets/images/location/map-marker-alt.png';
import ChevronDownBlack from '../../../../shared/assets/svg/my-accounts/chevronDown-Black.svg';
import { SCHEDULE } from '../../../../shared/utils/enums/AppIcons';

import Schedule from '../../OndemandDelivery/components/Schedule';

import { DAY_MMM_D_HH_MM } from '../../../../shared/utils/enums/dateFormats';
import {
  setPrevRoute,
  setTargetOrder,
  setSelectedCourierInfo,
} from '../../../../shared/redux/home/actions';
import FooterBtn from '../../../../shared/components/FooterBtn';
import useOnDemandCouriers from '../../../../shared/hooks/useOnDemandCouriers';
import CheckingLoader from '../../../../shared/components/v4/CheckingLoader';
import useStandardCouriers from '../../../../shared/hooks/useStandardCouriers';
import ErrorSelectedCourier from '../Home/components/Courier/ErrorSelectedCourier';
import SimilarCouriers from '../Home/components/Courier/SimilarCouriers';

import {
  setDropOff as setOnDemandDropOff,
  setLocation as setOnDemandLocation,
  setPointLocation as setOnDemandPointLocation,
  setPickUp as setOnDemandPickUp,
  setOrderId as setOnDemandOrderId,
  setVehicleType,
  setOnDemandItemType,
  setOnDemandCourierRates,
} from '../../../../shared/redux/onDemand/actions';
import {
  setDropOff as setStandardDropOff,
  setPickUp as setStandardPickUp,
  setLocation as setStandardLocation,
  setPointLocation as setStandardPointLocation,
  setOrderId as setStandardOrderId,
  setProduct,
  setStandardItemType,
} from '../../../../shared/redux/standard/actions';
import { useVoucherCenter } from '../../../../shared/hooks/useVoucherCenter';
import { setDefaults, setKey } from 'react-geocode';
import { MAPS_API_KEY, MAPS_GEOCODE } from '../../../../shared/utils/enums/AppConstants';
import { message } from 'antd';

const Form = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.usersReducer);
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);
  const product = useSelector(state => state.standardReducer.product);
  const userType = useSelector(state => state.usersReducer.userOrderTags.userType);

  const { formTab, view, prevRoute, selectedCourierInfo, targetOrder } = useSelector(
    state => state.homeReducer
  );

  const prevNavigate = { path: MODULE_PATH.HOME, state: {}, setAddressValue: {} };
  const isBookAgain = !isEmpty(targetOrder);

  const [deliveryType, setDeliveryType] = useState('');
  const [showScheduleDrawer, setScheduleDrawer] = useState(false);
  const [showItemDetailsDrawer, setItemDetailsDrawer] = useState(false);
  const [isFillUp, setIsFillUp] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [locationPrompted, setLocationPrompted] = useState(false);

  const isOndemand = isBookAgain
    ? !STANDARD_COURIERS.includes(targetOrder.courierId)
    : formTab.toLowerCase().includes('ondemand');

  const deliveryProps = isOndemand ? onDemandProps : standardProps;
  const pickUpLaterDisplay = `${moment(onDemandProps.pickupDateTime).format(DAY_MMM_D_HH_MM)}`;
  const setPointLocationPath = isOndemand ? setOnDemandPointLocation : setStandardPointLocation;
  const setLocationPath = isOndemand ? setOnDemandLocation : setStandardLocation;
  const productWeightAndDimension = `${product?.description?.dimensionText} • ${
    product?.description?.weightText || ''
  }`;
  const productSize = product && product.value.replace(' ', '_');
  const productSizeInitial = productSize[0] === 'E' ? 'XL' : productSize[0];

  const {
    pickUp,
    dropOff,
    courier: selectedCourier,
    itemType,
    itemApproxWeight,
    pickUpTime,
    vehicleType: selectedVehicle,
  } = deliveryProps;

  const courier = selectedCourier ? selectedCourier.courier : null;
  const isFromVehicleList = prevRoute === 'vehicleType';
  const isFromItemDetails = prevRoute === 'itemDetails';
  const isFromProductSizeList = prevRoute === 'productSize';
  const hasAddress = !isEmpty(pickUp?.address) && !isEmpty(dropOff?.address);
  const isPickupLater = pickUpTime === 'PickUpLater';

  const isFilledUpFields = [
    pickUp?.senderDetails?.address,
    dropOff?.recipientDetails?.address,
    itemType,
    courier,
  ].every(item => !isEmpty(item));

  const [courierDetails] = ENUM.COURIERS_LIST.couriers_logo.filter(name =>
    isBookAgain
      ? name.id.toLocaleLowerCase() === targetOrder.courierId.toLocaleLowerCase()
      : (name?.label || '').toLocaleLowerCase() === selectedCourierInfo.label.toLocaleLowerCase()
  );

  /* fetching */
  const { data, isLoading } = useVoucherCenter(userType);
  const { onDemandCouriers, isLoadingOnDemandCouriersQoute, errorOnDemandCouriersQoute } =
    useOnDemandCouriers(onDemandProps, userInfo, isOndemand, hasAddress, isFromItemDetails);

  const { standardCouriers, isLoadingStandardCouriersQoute, errorStandardCouriersQoute } =
    useStandardCouriers(standardProps, userInfo, !isOndemand, hasAddress, isFromItemDetails);

  const standardCourierQouteData = getStandardCourierQoute(standardCouriers, productSizeInitial);
  const couriersQouteData = isOndemand ? onDemandCouriers : standardCourierQouteData;

  const isLoadingQoutation = isOndemand
    ? isLoadingOnDemandCouriersQoute
    : isLoadingStandardCouriersQoute;

  const showLoader =
    hasAddress && isLoadingQoutation && isEmpty(selectedCourier) && isFromItemDetails;

  const showCourierRates =
    hasAddress && !isEmpty(couriersQouteData) && isEmpty(selectedCourier?.error);

  setDefaults({
    key: MAPS_API_KEY,
  });

  setKey(MAPS_API_KEY);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;

          const setLocation = async () => {
            const response = await axios.get(
              `${MAPS_GEOCODE}?latlng=${latitude},${longitude}&key=${MAPS_API_KEY}`
            );

            if (response.data.results[0]) {
              const resData = response.data.results[0];
              setCurrentAddress(
                response.data?.plus_code?.compound_code || resData?.formatted_address
              );
            }
          };

          setLocation();
        },

        error => {
          if (!locationPrompted) {
            message.info('Please turn on location', 3.0);
            setLocationPrompted(true);
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  useEffect(() => {
    if (isOndemand) {
      dispatch(setOnDemandCourierRates(onDemandCouriers.filter(data => data.success)));
    }
  }, [onDemandCouriers]);

  useEffect(() => {
    if (isOndemand && onDemandProps?.defaultTimeTag) {
      handleSetScheduleDrawer(true);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(onDemandProps?.orderId) || isEmpty(standardProps.orderId)) {
      const num = 1;
      dispatch(setStandardOrderId(generateOrderId(num)));
      dispatch(setOnDemandOrderId(generateOrderId(num)));
    }
    setDeliveryType(formTab);

    const pickupAddress = pickUp?.senderDetails?.address || pickUp.address;
    const dropOffAddress = dropOff?.recipientDetails?.address || dropOff.address;
    setIsFillUp([pickupAddress, dropOffAddress, itemType, courier].every(value => !isEmpty(value)));
  }, [onDemandProps, standardProps]);

  const navigateAfterDelay = useCallback(async () => {
    switch (prevRoute) {
      case 'DropOff':
        if (isOndemand && selectedVehicle?.default) {
          const { default: _, ...vehicleObj } = selectedVehicle;

          dispatch(setVehicleType(vehicleObj));
          navigate(MODULE_PATH.SHARED.VEHICLE);
        } else if (product?.default && !isOndemand) {
          const { default: _, ...productObj } = product;

          dispatch(setProduct(productObj));
          navigate(MODULE_PATH.STANDARD.PRODUCT_LIST);
        }
        break;
      case 'vehicleType':
      case 'productSize':
        setItemDetailsDrawer(true);
        break;
      default:
        return null;
    }
  }, [prevRoute, isOndemand, selectedVehicle, product, dispatch, navigate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigateAfterDelay(); // Call the async function inside a synchronous function
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [navigateAfterDelay]);

  useEffect(() => {
    if (isBookAgain && ['DONE', 'CANCELLED'].includes(targetOrder?.status)) {
      const pickupDetails = {
        barangay,
        province,
        city: pickupCity,
        address: pickupAddress,
        latitude: lat,
        longitude: long,
        senderDetails: {
          province,
          city: pickupCity,
          address: pickupAddress,
          contactNo: contactNumber,
          fullName: customerName,
          unitFloorHouse: gcash?.pickupUnitFloor,
          emailAddress: emailAddress || '',
        },
      };

      const dropOffDetails = {
        province: deliveryDetails.province,
        city: deliveryDetails.deliveryCity,
        address: deliveryDetails.deliveryAddress,
        barangay: deliveryDetails.barangay,
        recipientDetails: {
          province: deliveryDetails.province,
          city: deliveryDetails.deliveryCity,
          barangay: deliveryDetails.barangay,
          address: deliveryDetails.deliveryAddress,
          contactNo: deliveryDetails.contactNumber,
          fullName: deliveryDetails.customerName,
          unitFloorHouse: gcash?.dropOffUnitFloor,
        },
      };

      const {
        lat,
        long,
        meta,
        gcash,
        province,
        pickupCity,
        barangay,
        customerName,
        contactNumber,
        pickupAddress,
        emailAddress,
      } = targetOrder;
      const deliveryDetails = meta ? JSON.parse(meta) : {};

      const [courierDetails] = ENUM.COURIERS_LIST.couriers_logo.filter(
        name => name.id === targetOrder.courierId
      );

      dispatch(setSelectedCourierInfo(courierDetails));

      dispatch(setOnDemandItemType(gcash?.itemType));
      if (isOndemand) {
        dispatch(
          setOnDemandPickUp({
            latitude: lat,
            longitude: long,
            ...pickupDetails,
          })
        );
        dispatch(
          setOnDemandDropOff({
            latitude: deliveryDetails.lat,
            longitude: deliveryDetails.long,
            ...dropOffDetails,
          })
        );
      } else {
        dispatch(setStandardPickUp(pickupDetails));
        dispatch(setStandardDropOff(dropOffDetails));
      }
    }
  }, [targetOrder]);

  const handleVehicleIcon = value => {
    if (!value) return;
    let vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return ON_DEMAND_ICON[vehicleIcon];
  };

  const ProductIcon = value => {
    let productIcon = 'SMALL';
    const productSize = value.split(' ').join('_').toUpperCase();
    productIcon = COURIER_PRODUCT_SIZES[productSize];
    return STANDARD_ICON[productIcon];
  };

  const handleSetScheduleDrawer = bool => {
    setScheduleDrawer(bool);
  };

  const pathKey = isOndemand ? 'ON_DEMAND' : 'STANDARD';
  const MODULE_ADDRESS = isOndemand
    ? MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS
    : MODULE_PATH.STANDARD.SENDER_DETAILS;
  const MODULE_COURIER = MODULE_PATH.SHARED[pathKey].COURIER;
  const MODULE_PAYMENT_SUMMARY = MODULE_PATH.PAYMENT[pathKey].PAYMENT_SUMMARY;

  const onClickPickup = () => {
    dispatch(setPointLocationPath('PickUp'));

    if (!isEmpty(pickUp?.senderDetails)) {
      dispatch(setLocationPath(pickUp?.address));
      dispatch(setPrevRoute('PickUp'));
      return navigate(MODULE_ADDRESS, {
        state: { showAutoComplete: true, currentAddress: currentAddress },
      });
    }

    navigate(MODULE_ADDRESS);
  };

  const onClickDropOff = () => {
    dispatch(setPointLocationPath('DropOff'));

    if (!isEmpty(dropOff?.recipientDetails)) {
      dispatch(setLocationPath(dropOff?.address));
      dispatch(setPrevRoute('DropOff'));
      return navigate(MODULE_ADDRESS, {
        state: { showAutoComplete: true },
      });
    }

    navigate(MODULE_ADDRESS);
  };

  const selectedCourierId = selectedCourierInfo.id;
  let courierStanardNotExist = false;
  if (!isEmpty(standardCouriers)) {
    courierStanardNotExist = !standardCouriers.some(
      item => item.id.toLowerCase() === selectedCourierId.toLowerCase()
    );
  } else {
    courierStanardNotExist = !isEmpty(errorStandardCouriersQoute);
  }

  const reqsErr = !isEmpty(errorOnDemandCouriersQoute) || !isEmpty(errorStandardCouriersQoute);
  const showReviewOrderBtn = !isEmpty(selectedCourier?.error) || courierStanardNotExist || reqsErr;

  return (
    <div className="form__container">
      <StickyHeader title={ENUM.FORM.header_title} onPrevNavigate={prevNavigate} />
      <div className={`content blur-content`}>
        <div className={`header ${courierDetails?.label}`}>
          <img
            src={courierDetails?.logo}
            alt={`${courierDetails?.label} Logo`}
            width={96}
            height={32}
          />
          <div className="pickup-drop-off">
            <div className="form__input__wrapper">
              {onDemandProps.pickUpTime === 'PickUpLater' && (
                <div className={`pick__schedule`}>
                  <span className="bolt">
                    <img src={SCHEDULE.V4_CLOCK} alt="arrow down" />
                  </span>
                  <span className="later-value lbl-base">
                    Later <span>({pickUpLaterDisplay})</span>
                  </span>
                  <span className="selected__icon" onClick={() => handleSetScheduleDrawer(true)}>
                    <img src={ChevronDownBlack} alt="arrow down" />
                  </span>
                </div>
              )}
              <div className="form__input">
                <div className="map-marker">
                  <img src={startMapMarker} alt="end-map-marker" />
                  <img src={endMapMarker} alt="start-map-marker" />
                </div>
                <div className="input-field">
                  <div className="pick-up-time">
                    {isEmpty(pickUp?.senderDetails?.address) &&
                    isEmpty(pickUp?.senderDetails?.fullName) ? (
                      <input
                        className="pickup-input lbl-base-light"
                        placeholder="Pickup location"
                        defaultValue=""
                        value={
                          pickUp?.senderDetails?.fullName || pickUp?.senderDetails?.address || ''
                        }
                        onClick={() => onClickPickup()}
                        readOnly
                      />
                    ) : (
                      <div className="user-info" onClick={() => onClickPickup()}>
                        <span className="no-wrap mlLbl-base">
                          {Ellips(
                            pickUp?.senderDetails?.fullName || pickUp?.senderDetails?.address
                          )}
                        </span>
                        <span className="address-info cntnt-caption">{pickUp.address}</span>
                      </div>
                    )}

                    {isOndemand && onDemandProps.pickUpTime === 'PickUpNow' && (
                      <div className="do-picker" onClick={() => handleSetScheduleDrawer(true)}>
                        <span className="lbl-base">Now</span>
                        <img src={ChevronDownBlack} alt="arrow down" />
                      </div>
                    )}
                  </div>

                  {isEmpty(dropOff?.recipientDetails?.address) &&
                  isEmpty(dropOff?.recipientDetails?.fullName) ? (
                    <input
                      className="dropoff-input lbl-base-light"
                      placeholder="Drop-off location"
                      defaultValue=""
                      value={
                        dropOff?.recipientDetails?.fullName ||
                        dropOff?.recipientDetails?.address ||
                        ''
                      }
                      onClick={() => onClickDropOff()}
                      readOnly
                    />
                  ) : (
                    <div
                      className="user-info"
                      onClick={() => {
                        onClickDropOff();
                      }}
                    >
                      <span className="no-wrap mlLbl-base">
                        {Ellips(
                          dropOff?.recipientDetails?.fullName || dropOff?.recipientDetails?.address
                        )}
                      </span>
                      <span className="address-info cntnt-caption">{dropOff.address}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <img
            className="background-logo"
            src={courierDetails?.logo}
            alt="Logo"
            width={280}
            height={160}
          />
        </div>
        {showLoader && (
          <div className="checking__loader__container">
            <CheckingLoader />
          </div>
        )}
        {hasAddress ? (
          showReviewOrderBtn ? (
            <div className="error__selected__courier">
              <ErrorSelectedCourier
                isNotServiceable={courierStanardNotExist}
                label={reqsErr ? '' : selectedCourierInfo.label}
                errorMessage={selectedCourier?.error}
              />
              <div className="available__couriers__container">
                <SimilarCouriers
                  orderType="onDemand"
                  title={ENUM.COURIERS_LIST.pickup_now_title}
                  isReselect={isOndemand ? true : false}
                  currentCourierSelected={selectedCourierInfo?.label}
                />
                <SimilarCouriers
                  orderType="standard"
                  title={ENUM.COURIERS_LIST.pickup_later_title}
                  isReselect={!isOndemand ? true : false}
                  currentCourierSelected={selectedCourierInfo?.label}
                />
              </div>
              ;
            </div>
          ) : (
            <div
              className={`selection__fields__container v4 ${isPickupLater ? 'pickupLater' : ''}`}
            >
              {isOndemand && (
                <FieldSelection
                  className={`motorcycle ${deliveryType}`}
                  imageName="Motorcycle"
                  imageSrc={handleVehicleIcon(selectedVehicle.vehicleType)}
                  onClick={() => {
                    navigate(MODULE_PATH.SHARED.VEHICLE, { state: { view: view } });
                  }}
                  title={formatUnderscoredText(selectedVehicle.vehicleType)}
                  description={`${
                    (isOndemand && selectedVehicle?.description?.dimensionText) || ''
                  } • ${(isOndemand && selectedVehicle?.description?.weightText) || ''}`}
                  refIdx="refVehicleType"
                  selectedVehicle={selectedVehicle}
                />
              )}
              {!isOndemand && (
                <FieldSelection
                  className={`product-size ${deliveryType}`}
                  imageName="Product size"
                  imageSrc={ProductIcon(standardProps?.product?.value || '')}
                  onClick={() => {
                    navigate(MODULE_PATH.STANDARD.PRODUCT_LIST, { state: { view: view } });
                  }}
                  title={standardProps?.product?.value}
                  description={productWeightAndDimension}
                  standardProps={standardProps}
                />
              )}
              <FieldSelection
                className="item-details"
                imageName="Item"
                imageSrc={IconItemDetails}
                title={capitalizeName(itemType) || 'Add item details'}
                description={itemType ? parseFloat(itemApproxWeight) : 'Item description'}
                refIdx="refItemDetails"
                itemType={itemType}
                onClick={() => {
                  setItemDetailsDrawer(true);
                }}
              />
            </div>
          )
        ) : (
          <>
            <div className={`similar-courier ${isPickupLater ? 'pickupLater' : ''}`}>
              <span className="hdln-block">{ENUM.FORM.similar_couriers}</span>
              <CourierList
                orderType={courierDetails?.type}
                currentCourierSelected={selectedCourierInfo?.label}
                fromForm="form-courier-list"
                isReselect={false}
              />
            </div>
            <VoucherLists
              data={data}
              isLoading={isLoading}
              userType={userType}
              fromForm="form-courier-vouchers"
            />
          </>
        )}
        {showCourierRates && !courierStanardNotExist && !reqsErr && (
          <CourierRates couriersQouteData={couriersQouteData} isOnDemand={isOndemand} />
        )}
        <ItemDetailsList
          show={showItemDetailsDrawer}
          onCloseItemDetailsDrawer={() => setItemDetailsDrawer(false)}
        />
      </div>
      <Schedule
        show={showScheduleDrawer}
        setScheduleDrawer={() => {
          handleSetScheduleDrawer(false);
        }}
        onSetVehicle={() => {}}
      />

      {!showReviewOrderBtn && (
        <FooterBtn
          className={`form-btn ${!isFilledUpFields ? 'disable' : ''} ${
            (isLoadingOnDemandCouriersQoute || isLoadingStandardCouriersQoute) &&
            isEmpty(selectedCourier)
              ? 'hide-footer'
              : ''
          }`}
          childClass="radius"
          label="Review Order"
          onClick={() => {
            if (!isFilledUpFields) return;
            navigate(MODULE_PAYMENT_SUMMARY);
          }}
        />
      )}
    </div>
  );
};

export default memo(Form);
