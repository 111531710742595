import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import DEF_BANNER from '../../../../../shared/assets/images/Banners/Banner-3x.png';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { setBannersList } from '../../../../../shared/redux/banner/actions';
import { ZONE } from '../../../../../shared/utils/enums/AppConstants';

const Banner = ({ bannersList, setBannersList }) => {
  const storage = getStorage();

  const { appInfo } = useSelector(state => state.appAccessReducer);

  const [banners, setBanners] = useState([]);

  const fetchBanners = async () => {
    // const isPreProduction = ZONE.ENV === 'PRE-PRODUCTION';
    // const folderPath = `GCASH/${isPreProduction ? 'PRE_PROD_BANNER' : 'BANNERS'}`;
    const folderPath = `GCASH/BANNERS'}`;
    try {
      const urls = [];
      let fileNumber = 1;
      let maxFiles = 10;

      while (fileNumber <= maxFiles) {
        const fileName = `carousel-img${fileNumber}-pink.png`;
        const fileRef = ref(storage, `${folderPath}/${fileName}`);
        try {
          const downloadUrl = await getDownloadURL(fileRef);
          urls.push(downloadUrl);
          fileNumber++;
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            break;
          } else {
            break;
          }
        }
      }

      setBanners(urls);
      setBannersList(urls);
    } catch (error) {
      setBanners([DEF_BANNER]);
      setBannersList([DEF_BANNER]);
    }
  };

  useEffect(() => {
    if (!bannersList.length) {
      fetchBanners();
    } else {
      setBanners(bannersList);
    }
  }, [bannersList]);

  const sortImagesByOrder = (imageArray, orderArray) => {
    var indexMap = {};
    imageArray.forEach((image, index) => {
      indexMap[image] = index;
    });

    imageArray.sort((a, b) => {
      var indexA = indexMap[a];
      var indexB = indexMap[b];
      var orderIndexA = orderArray.indexOf(String(indexA + 1));
      var orderIndexB = orderArray.indexOf(String(indexB + 1));

      if (orderIndexA === -1 && orderIndexB === -1) {
        return 0;
      }

      if (orderIndexA !== -1 && orderIndexB === -1) {
        return -1;
      }

      if (orderIndexA === -1 && orderIndexB !== -1) {
        return 1;
      }
      return orderIndexA - orderIndexB;
    });
  
    return imageArray;
  }

  const newBanner = banners.length ? sortImagesByOrder(banners.filter(url => !url.includes("Banner-3x")), appInfo?.bannerOrder || []) : [DEF_BANNER];

  return (
    <div className='banner'>
      <Carousel dots={false} autoplay >
          {newBanner.map((img, idx) => (
            <div key={`container--${idx}`}>
              <img key={`banner--${idx}`} src={img} alt="Parcel Banner" className="parcel-banner" />
            </div>
          ))}
        </Carousel>
    </div>
  );
};

const mapStateToProps = state => ({
  propsUser: state.usersReducer.currentUser,
  bannersList: state.bannerReducer.bannersList,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setBannersList,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
