import React, { memo } from 'react';
import { message } from 'antd';
import SOON from '../../../../../shared/assets/svg/home-booking/soon.svg';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { useDispatch, useSelector } from 'react-redux';
import { setFormTab, setSelectedCourierInfo } from '../../../../../shared/redux/home/actions';
import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';
import { ENUM } from '../../../../../shared/utils/enums/v4/AppConstants';
import { setCourier as setOnDemandCourier } from '../../../../../shared/redux/onDemand/actions';
import { setCourier as setStandardCourier } from '../../../../../shared/redux/standard/actions';

const CourierList = ({
  setCourierDrawer,
  orderType = '',
  currentCourierSelected = '',
  fromForm = '',
  isReselect = false,
  isDrawer = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const courierState = useSelector(state => state.onDemandReducer.courier);

  let listOfCouriers = ENUM.COURIERS_LIST.couriers_logo.slice(isDrawer ? -4 : -5);

  if (orderType) {
    listOfCouriers = ENUM.COURIERS_LIST.couriers_logo.filter(
      courier => courier.type === orderType && (courier.label).toLowerCase() !== (currentCourierSelected).toLowerCase()
    );
  }

  const handleFormOrDrawer = courierInfo => {
    const { status, label: courierName, type: serviceType } = courierInfo;

    if (courierInfo.status === 'inactive') {
      return message.info('Coming Soon!', 3.0);
    }

    if (isReselect === true) {
      serviceType === 'onDemand'
        ? dispatch(setOnDemandCourier({}))
        : dispatch(setStandardCourier({}));
    }

    const formPath =
      serviceType === 'onDemand' ? MODULE_PATH.ON_DEMAND.FORM : MODULE_PATH.STANDARD.FORM;

    dispatch(setSelectedCourierInfo(courierInfo));
    dispatch(setFormTab(serviceType));

    if (status == 'inactive') return;
    if (courierName === 'All') return setCourierDrawer();

    return navigate(formPath);
  };

  return (
    <div className={`courier__list__container ${fromForm} ${isDrawer ? 'drawer': ''}`}>
      {listOfCouriers.map(courier => {
        let errorCourier = '';
        if (
          !isEmpty(courierState?.error) &&
          courier.label.toLowerCase() === courierState.courier.toLowerCase()
        ) {
          errorCourier = 'error-courier';
        }

        return (
          <div
            className={`couriers ${courier.label} ${errorCourier}`}
            key={courier.label}
            onClick={() => handleFormOrDrawer(courier)}
          >
            <div className="logo-list">
              <img src={courier.logo} alt={courier.label} />
            </div>
            {courier.status === 'inactive' && <img src={SOON} className="corner-image" />}
            <div className={`${courier.status === 'inactive' ? 'soon' : ''} label cntnt-caption`}>
              {courier.label}{' '}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(CourierList);
