import ReferralDAO from '../../utils/dao/ReferralDAO';
import {
  SET_REFERRAL_CODE,
  SET_REFER_REDEMPTIONS,
  SET_REFERRAL_OFFER,
  SET_FETCHED,
  SET_REFERRER_USER_ID,
} from '../enums/types';

export const setReferralCode = referralCode => ({
  type: SET_REFERRAL_CODE,
  payload: referralCode,
});

export const setReferralOffer = referralOffer => ({
  type: SET_REFERRAL_OFFER,
  payload: referralOffer,
});

export const setReferrerUserId = referrerUserId => ({
  type: SET_REFERRER_USER_ID,
  payload: referrerUserId,
});

export const setReferRedemptions = referRedemptions => ({
  type: SET_REFER_REDEMPTIONS,
  payload: referRedemptions,
});

export const setFetched = fetched => ({
  type: SET_FETCHED,
  payload: fetched,
});

export const fetchReferralOffer = () => {
  return async (dispatch, getState) => {
    const referralDao = new ReferralDAO();
    const referralOffer = await referralDao.getReferralOffer({ name: 'REFEREE' });
    dispatch(setReferralOffer(referralOffer.result?.amount || ''));
  };
};

export const fetchReferralCode = userId => {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.usersReducer.currentUser;

    const referralDao = new ReferralDAO();
    const referralCode = await referralDao.getReferralCode({ userId: userId || user.id });
    dispatch(setReferralCode(referralCode.result?.referralCode || ''));
  };
};

export const fetchReferralRedemptions = userId => {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.usersReducer.currentUser;

    const referralDao = new ReferralDAO();
    const redemptions = await referralDao.getReferralCodeUsage({ userId: userId || user.id });
    dispatch(setReferRedemptions(redemptions?.result || []));
  };
};
