import React, { useContext, useEffect } from 'react';
import StickyHeader from '../../../shared/components/StickyHeader';
import PickupDropOff from './components/PickupDropOff';
import VehicleType from '../../../shared/components/VehicleType';
import ItemDetails from '../../../shared/components/ItemDetails';
import Courier from '../../../shared/components/Courier';
import { ICON } from '../../../shared/utils/enums/AppIcons';
import FooterBtn from '../../../shared/components/FooterBtn';
import { MODULE_PATH } from '../../../shared/constants/Module';
import { useDispatch, useSelector } from 'react-redux';
import {
  generateOrderId,
  setTimeInterval,
  getServerTime,
} from '../../../shared/utils/helpers/purefunctions';
import {
  setDropOff,
  setOnDemandItemType,
  setOnDemandPartners,
  setOrderId,
  setPickUp,
  setPickupDateTime,
} from '../../../shared/redux/onDemand/actions';

import { setPickupDateTime as setStandardPickupDateTime } from '../../../shared/redux/standard/actions';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { DELIVERY_TYPE_VIEW } from '../../../shared/utils/enums/AppConstants';

function OnDemandDelivery() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDemand = useSelector(state => state.onDemandReducer);
  const standard = useSelector(state => state.standardReducer);
  const targetOrder = useSelector(state => state.homeReducer.targetOrder);

  const [setServerTime, onSetServerTime] = useState(false);
  const [isFillUp, setIsFillUp] = useState(false);

  const prevNavigate = { path: '/', state: {} };
  const nextNavigate = { label: 'On-demand Delivery', imgPath: ICON.ON_DEMAND };

  const {
    lat,
    long,
    meta,
    gcash,
    status,
    province,
    pickupCity,
    customerName,
    contactNumber,
    pickupAddress,
  } = targetOrder;
  const deliveryDetails = meta ? JSON.parse(meta) : {};
  const { pickUp, dropOff, itemType, courier } = onDemand;

  const { formTab } = useSelector(state => state.homeReducer);

  useEffect(() => {
    if (!isEmpty(targetOrder) && ['DONE', 'CANCELLED'].includes(status)) {
      dispatch(setOnDemandItemType(gcash.itemType));
      dispatch(
        setPickUp({
          province,
          city: pickupCity,
          address: pickupAddress,
          latitude: lat,
          longitude: long,
          senderDetails: {
            province,
            city: pickupCity,
            address: pickupAddress,
            contactNo: contactNumber,
            fullName: customerName,
            unitFloorHouse: gcash.dropOffUnitFloor,
          },
        })
      );
      dispatch(
        setDropOff({
          province: deliveryDetails.province,
          city: deliveryDetails.deliveryCity,
          address: deliveryDetails.deliveryAddress,
          latitude: deliveryDetails.lat,
          longitude: deliveryDetails.long,
          recipientDetails: {
            province: deliveryDetails.province,
            city: deliveryDetails.deliveryCity,
            address: deliveryDetails.deliveryAddress,
            contactNo: deliveryDetails.contactNumber,
            fullName: deliveryDetails.customerName,
            unitFloorHouse: gcash.pickupUnitFloor,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    setIsFillUp(
      [pickUp.senderDetails, dropOff.recipientDetails, itemType, courier].every(
        value => !isEmpty(value)
      )
    );
  }, [pickUp.senderDetails, dropOff.recipientDetails, itemType, courier]);

  useEffect(() => {
    if (onDemand?.orderId === '') {
      let num = 1;
      const set_OrderId = generateOrderId(num);
      dispatch(setOrderId(set_OrderId));
    }
  }, [dispatch, onDemand]);

  useEffect(() => {
    !setServerTime && setCurrentDateAndTime();
  }, [setServerTime]);

  const setCurrentDateAndTime = async () => {
    const currDateTime = await getServerTime();
    const intervalPres = 300000;

    if (formTab.toLowerCase().includes('ondemand')) {
      // removed: pickupDateTime default value is null
      // if (onDemand.pickupDateTime && onDemand.pickupDateTime % intervalPres === 0) {
      //   dispatch(setPickupDateTime(onDemand.pickupDateTime));
      // } else {
      //   const dateTime = setTimeInterval(currDateTime);
      //   dispatch(setPickupDateTime(dateTime));
      // }
    } else {
      if (standard.pickupDateTime && standard.pickupDateTime % intervalPres === 0) {
        dispatch(setStandardPickupDateTime(standard.pickupDateTime));
      } else {
        const dateTime = setTimeInterval(currDateTime);
        dispatch(setStandardPickupDateTime(dateTime));
      }
    }

    onSetServerTime(true);
  };

  const ReviewButton = ({ label }) => {
    if (!isFillUp) {
      return (
        <div className="footer__btn disable">
          <div className="btn">{label}</div>
        </div>
      );
    }
    return (
      <FooterBtn label={label} onClick={() => navigate(MODULE_PATH.PAYMENT.PAYMENT_SUMMARY)} />
    );
  };

  return (
    <>
      <StickyHeader title="New Order" onPrevNavigate={prevNavigate} onNextNavigate={nextNavigate} />
      <div className="ondemand__delivery__container">
        <div className="content">
          <PickupDropOff />
          <ItemDetails />
          <VehicleType />
          <Courier />
        </div>
        <ReviewButton label="Review Order" />
      </div>
    </>
  );
}

export default OnDemandDelivery;
