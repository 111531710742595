import React, { useEffect, useState } from 'react';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import { useLocation, useNavigate } from 'react-router';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import Vouchers from './Vouchers';
import { useDispatch, useSelector } from 'react-redux';
import { useVoucherCenter } from '../../../../../shared/hooks/useVoucherCenter';
import {
  getAmountOff,
  getCourierLogo,
  getTimeLeft,
} from '../../../../../shared/utils/helpers/purefunctions';
import { COURIER_LOGO } from '../../../../../shared/utils/enums/CourierImages';
import {
  setSelectedVoucherDetails,
  setVoucherCode,
  setOndemandReState,
} from '../../../../../shared/redux/onDemand/actions';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import FooterBtn from '../../../../../shared/components/FooterBtn';

import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';
import { defCode } from '../../../../../shared/utils/enums/AppConstants';
import {
  setReferralCode,
  setReferrerUserId,
} from '../../../../../shared/redux/referral/action';


const SelectVoucher = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userType = useSelector(state => state.usersReducer.userOrderTags.userType);
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const referrerUserId = useSelector(state => state.referralReducer.referrerUserId);

  const [searchVoucherInput, setSearchVoucherInput] = useState('');
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const [selectedCode, setSelectedCode] = useState('');
  const [selectedCodeDetails, setSelectedCodeDetails] = useState({});
  const [hasSelectedOrSearchedVoucherCode, setHasSelectedOrSearchedVoucherCode] = useState(false);

  const { data, isLoading, error } = useVoucherCenter(userType);
  
  useEffect(() => {
    if (!isEmpty(onDemandProps.voucherCode)) {
      setSelectedCode(onDemandProps.voucherCode)
      setSelectedCodeDetails(onDemandProps?.selectedVoucherDetails)
    }
  }, [onDemandProps]);

  const prevNavigate = {
    label: 'Voucher Center',
    imgPath: '',
    path: MODULE_PATH.PAYMENT.ON_DEMAND.PAYMENT_SUMMARY,
  };

  const handleVoucherSelect = (voucherData, index) => {
    if (selectedVoucher === index) {
      setSelectedVoucher(null);
      dispatch(setVoucherCode(''));
      return;
    }
    setSelectedVoucher(index);
    setSelectedCode(voucherData?.voucherCode)
    setSelectedCodeDetails(voucherData)
    setHasSelectedOrSearchedVoucherCode(true);
  };

  const handleChange = e => {
    const { value } = e.target;
    setSearchVoucherInput(value);
    setHasSelectedOrSearchedVoucherCode(true);
    setSelectedVoucher(null);
  };

  const hancleClear = () => {
    setSearchVoucherInput('');
    setSelectedVoucher(null);
    setHasSelectedOrSearchedVoucherCode(false);
  };

  const reorderedData = data.sort((a, b) => {
    const aMatches =
      searchVoucherInput && a.voucherCode.toLowerCase().includes(searchVoucherInput.toLowerCase());

    const bMatches =
      searchVoucherInput && b.voucherCode.toLowerCase().includes(searchVoucherInput.toLowerCase());
    return bMatches - aMatches;
  });

  const selectedDataBySearched =
    searchVoucherInput && data.filter(details => (details.voucherCode).toLowerCase() === (searchVoucherInput).toLowerCase());

  const handleNavigateBack = () => {
    dispatch(setReferrerUserId(""));
    dispatch(setReferralCode(""));
    dispatch(setVoucherCode(selectedCode));
    dispatch(setSelectedVoucherDetails(selectedCodeDetails));

    searchVoucherInput && dispatch(setVoucherCode(searchVoucherInput));
    navigate(prevNavigate.path, { state: { isEmptyVoucherList } });
  };

  const handleBackSetCode = () => {
    if (isEmpty(searchVoucherInput) && !isEmpty(onDemandProps?.selectedVoucherDetails)) {
      dispatch(setOndemandReState(onDemandProps))
      setHasSelectedOrSearchedVoucherCode(true);
    }
  }

  const isEmptyVoucherList = reorderedData.length <= 0;

  const DisplayVoucherList = () => {
    if ((!data && data.length === 0) || error || isEmptyVoucherList) {
      return <div style={{ marginTop: '50%', textAlign: 'center' }}>No avaible vouchers.</div>;
    }

    if (isLoading) return <VoucherCardLoader count={3} />;
    const priorityVoucherCode = onDemandProps?.voucherCode || '';
    const sortedData = priorityVoucherCode
      ? [...reorderedData].sort((a, b) =>
          a.voucherCode === priorityVoucherCode ? -1 : b.voucherCode === priorityVoucherCode ? 1 : 0
        )
      : reorderedData;

    return sortedData.map((data, index) => {
      const expirationLeft = getTimeLeft(data.expiration);
      const amountOff = getAmountOff(data);
      const logo = getCourierLogo(data, ICON.PINK_PARCELS_LOGO, COURIER_LOGO);
      // const isSearched =
      //   searchVoucherInput && data.voucherCode.toLowerCase() === searchVoucherInput.toLowerCase();
      const voucherData = searchVoucherInput ? selectedDataBySearched : data;

      return (
        <Vouchers
          index={index}
          voucherData={voucherData}
          expirationLeft={expirationLeft}
          description={data.description}
          amountOff={amountOff}
          from="select-voucher"
          searchVoucherInput={searchVoucherInput}
          logo={logo}
          voucherIcon={ICON.VOUCHER_CARD_ICON_GREEN}
          isSelected={data.voucherCode.toLowerCase() === selectedCode.toLowerCase()}
          selectedVoucher={selectedCode.toLowerCase()}
          onSelect={() => handleVoucherSelect(voucherData, index)}
          prevPath={location.pathname}
        />
      );
    });
  };

  let refData = { ...defCode, voucherCode: onDemandProps.voucherCode }
  return (
    <div className="select__voucher__container">
      <StickyHeader title="Select Available Vouchers" onPrevNavigate={prevNavigate} callback={() => handleBackSetCode() } />
      <div className="search-voucher flex-col gap-24px">
        <div className="content flex-row items-center gap-8px">
          <img src={ICON.VOUCHER_ICON} alt="Voucher" />
          <input
            type="text"
            value={searchVoucherInput}
            onChange={e => handleChange(e)}
            placeholder="Enter Voucher or Referral Code"
          />
          {searchVoucherInput && <span onClick={hancleClear}>Remove</span>}
        </div>
      </div>
      <div className='discount-or-cashback'>

      {
        !isEmpty(referrerUserId) && (
          <div>
            {!isEmptyVoucherList && <div className="title">Referral</div>}
              <Vouchers
                index={-1}
                voucherData={refData}
                expirationLeft={getTimeLeft(refData.expiration)}
                description={refData.description}
                amountOff={getAmountOff(refData)}
                from="select-voucher"
                searchVoucherInput={searchVoucherInput}
                logo={ICON.PINK_PARCELS_LOGO}
                voucherIcon={ICON.VOUCHER_CARD_ICON_GREEN}
                isSelected={refData.voucherCode.toLowerCase() === selectedCode.toLowerCase()}
                selectedVoucher={selectedCode.toLowerCase()}
                onSelect={() => handleVoucherSelect(refData, -1)}
                prevPath={location.pathname}
                referral={true}
              />
          </div>
        )
      }
      <div>
        {!isEmptyVoucherList && <div className="title">Discount / Cashback</div>}
        <DisplayVoucherList />
      </div>
      </div>
      {!isEmptyVoucherList && (
        <FooterBtn
          className={`select-voucher ${!hasSelectedOrSearchedVoucherCode ? 'disabled' : ''}`}
          childClass="radius"
          label="Confirm Voucher"
          onClick={handleNavigateBack}
        />
      )}
    </div>
  );
};

export default SelectVoucher;
