import React from 'react';
import { ICON } from '../../../../../../shared/utils/enums/v4/AppIcon';
import { ENUM } from '../../../../../../shared/utils/enums/v4/AppConstants';

const ErrorSelectedCourier = ({ errorMessage, isNotServiceable = false, label = '' }) => {
  return (
    <div className="error__courier">
      <img src={ICON.ERROR} alt="Error" />
      {isNotServiceable && label ? (
        <div className="error-message cntnt-base">
          <span>This address is outside<span>{` ${label}’s `}</span>service area.</span>
          <span>{ENUM.COURIERS_LIST.error_label}</span>
        </div>
      ) : (
        <div className="error-message">
        <span>{errorMessage ? `${errorMessage}.` : ''}</span>
          <span>{ENUM.COURIERS_LIST.error_label}</span>
        </div>
      )}
    </div>
  );
};

export default ErrorSelectedCourier;
