import React from 'react';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import { useSelector } from 'react-redux';

import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';

const Vouchers = ({
  from,
  logo,
  voucherIcon,
  isSelected = false,
  onSelect,
  description,
  expirationLeft,
  amountOff,
  voucherData,
  searchVoucherInput,
  selectedVoucher,
  prevPath,
  index,
  referral = false,
}) => {
  const navigate = useNavigate();
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const selectedCode = searchVoucherInput || selectedVoucher;

  // const isFromTC = from === 'voucher-tc';
  // const isFromVoucherCenter = from === 'voucher-center';
  const isSelectVoucher = from === 'select-voucher';
  const selected = isSelected === true && 'selected';
  const seachedVoucherCode = searchVoucherInput ? 'searched-voucher' : 'no-searched';

  const justifyStyle = 'justify-start';
  const gapStyle = '';

  // const isDisabled = !(isSelected || !isEmpty(onDemandProps?.voucherCode))

  let voucherEnabledOrDisabledStyle = {
    opacity: 1,
  };
  if (!isEmpty(selectedCode) && !isSelected) {
    voucherEnabledOrDisabledStyle = {
      opacity: 0.75,
    };
  }

  const CircleIcon = () => {
    if (isSelected) {
      return <img src={ICON.CHECKED_CIRCLE} alt="Checked Circle" />;
    }

    return <img src={ICON.UNCHECK_CIRCLE} alt="Unchecked Circle" />;
  };

  const handleNavigateToTC = data => {
    if (!isSelectVoucher) {
      navigate(MODULE_PATH.EXTRAS.VOUCHER_TC, {
        state: { link: prevPath, voucherData: data },
      });
    }
  };

  return (
    <div
      className={`voucher__container ${from} ${selected} ${seachedVoucherCode}`}
      style={from === 'select-voucher' ? voucherEnabledOrDisabledStyle : {}}
      key={index}
      onClick={isSelectVoucher ? () => onSelect(voucherData, index) : undefined}
    >
      <div
        className={`voucher-card flex-row items-center ${justifyStyle} ${gapStyle}`}
        onClick={() => handleNavigateToTC(voucherData)}
      >
        <img src={logo} alt="Logo" />
        <div className="vertical-dotted-line"></div>
        <div className="description flex-col gap-8px justify-start">
          <div className="promo-lable flex-row gap-8px items-center justify-start">
            <img src={voucherIcon} alt="Voucher icon" />
            <span className="hdln-block">{referral ? onDemandProps?.voucherCode : amountOff}</span>
          </div>
          {!referral && <div className="promo-description">{description}</div>}
          {!referral && (
            <span className="expiration">
              {`expiring: ${expirationLeft}`}
              <span
                className="text-primary"
                onClick={e => {
                  e.stopPropagation();
                  navigate(MODULE_PATH.EXTRAS.VOUCHER_TC, {
                    state: { link: prevPath, voucherData: voucherData },
                  });
                }}
              >
                T&C
              </span>
            </span>
          )}
        </div>
        {from === 'select-voucher' && <CircleIcon />}
      </div>
    </div>
  );
};

export default Vouchers;
