import React, { memo, useEffect, useState } from 'react';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import {
  capitalizeFirstChar,
  formatEnumString,
} from '../../../../../shared/utils/helpers/purefunctions';
import { useSelector } from 'react-redux';
import TypingEffect from '../../../../../shared/components/v4/TypingEffect';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { useNavigate } from 'react-router';
import { ENUM } from '../../../../../shared/utils/enums/v4/AppConstants';

const Header = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');

  const { welcome_title, text } = ENUM.HEADER;

  const firstName = useSelector(state => state.usersReducer.currentUser.firstName);
  const welcomeTitle = formatEnumString(welcome_title, { name });

  useEffect(() => {
    setName(capitalizeFirstChar(firstName));
  }, [firstName]);

  const handleNavigateAllOrder = () => {
    navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS, { state: { path: MODULE_PATH.HOME } });
  };

  return (
    <div className="header__container">
      <div className="title cntnt-large-bold">
        <img src={ICON.PINK_PARCELS_LOGO} alt="Parcels logo" />
        <span>{welcomeTitle}</span>
      </div>
      <div className="search" onClick={handleNavigateAllOrder}>
        <img src={ICON.V4_SEARCH} alt="Search" />
        <span>{text.search}</span>
        <TypingEffect text={text.orders.toLowerCase()} />
      </div>
      <img
        className="background-logo"
        src={ICON.V4_HEADER_LOGO}
        alt="Logo"
        width={122}
        height={122}
      />
    </div>
  );
};

export default memo(Header);
