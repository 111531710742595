/* global my */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { MODULE_PATH } from '../../shared/constants/Module';
import HomeV3 from './Home/v3/index';
import HomeV4 from './v4/Home/index';
import AddressBook from './AddressBooks';
import AddressBookForm from './AddressBooks/AddressBookForm';
import AddressBookPinMapLocation from './AddressBooks/AddressBookForm/MapLocation';
import OnDemandDelivery from './OndemandDelivery';
import VehicleTypes from '../../shared/components/VehicleTypeList';
import ItemDetailsList from '../../shared/components/ItemDetailsList';
import CouriersList from '../../shared/components/CouriersList';

import StandardDelivery from './StandardDelivery';
import SenderDetails from './StandardDelivery/components/SenderDetails';
import SelectAddress from './StandardDelivery/components/SelectAddress';
import MyAccounts from './Home/myAccounts';
import AccountDetails from './Home/myAccounts/components/AccountDetails';
import CashBack from './Home/myAccounts/components/CashBack';
import ContactUs from './Home/myAccounts/components/ContactUs';
import AboutUs from './Home/myAccounts/components/AboutUs';
import PrivacyPolicy from './Home/myAccounts/components/PrivacyPolicy';
import TermsAndConditions from './Home/myAccounts/components/TermsAndConditions';
import Referrals from './Home/myAccounts/components/Referrals';
import SelectPickupAddress from './Home/components/SelectPickupAddress';
import ViewAllOrders from './Home/orders/components/ViewAllOrders';
import PaymentBreakdown from './Payment/PaymentBreakdown';
import SearchLocation from './Home/components/SearchLocation';
import PickupDetails from './Home/components/PickupDetails';
import SavedPlacesForm from './Home/components/SavedPlacesForm';
import OrderDetails from './Home/orders/components/orderDetails';
import PaymentProcess from './Payment/PaymentProcess';
import Payment from './Payment/Payment';
import CancelOrder from './OndemandDelivery/components/CancelOrder';
import Loader from './OndemandDelivery/elements/Loader';
import OrderCancelled from './OndemandDelivery/elements/OrderCancelled';
import Error from './OndemandDelivery/elements/Error';
import RefundError from './OndemandDelivery/elements/Error';

import Help from './OndemandDelivery/components/Help';
import HelpCenter from './OndemandDelivery/components/HelpCenter';
import SearchOders from './OndemandDelivery/elements/SearchOrder';
import ComingSoon from '../../shared/components/ComingSoon';
import ComingSoonIOS from '../../shared/components/ComingSoonPage';
import ProductList from './StandardDelivery/components/ProductList';
import StandardCourierList from './StandardDelivery/components/StandardCourierList';
import OrderHistory from './StandardDelivery/components/OrderHistory';
import FailedOrder from '../../shared/components/FailedOrder';
import { InputItemDescription } from '../../shared/context/ItemDescriptionContext';
import { InputItemRate } from '../../shared/context/ItemRateContext';
import { InputItemType } from '../../shared/context/ItemTypeContext';
import MaintenancePage from '../../shared/components/MaintenancePage';
import Unauthorized from '../../app/modules/external/UnauthorizedPage';
import { useDispatch } from 'react-redux';
import { setAppAccess } from '../../shared/redux/app/actions';
import { APP_ACCESS, USER_INFO_MESSAGE, ZONE } from '../../shared/utils/enums/AppConstants';
import PaymentSummary from './Payment';
import { setAppInfo, setFlags } from '../../shared/redux/app/actions';
import Login from '../../shared/utils/dao/Login';
import SearchNearbyRiders from '../../shared/components/v2/SearchNearbyRiders';
import AppInfoDAO from '../../shared/utils/dao/App';
import { setCurrentUser, setTags } from '../../shared/redux/users/actions';
import ProviderMaps from '../../shared/components/v2/ProviderMaps';
import { isEmpty } from '../../shared/utils/helpers/purefunctions';
import VoucherCenter from './Home/v3/components/VoucherCenter';
import VoucherTCs from './Home/v3/components/VoucherT&Cs';
import SelectVoucher from './Home/v3/components/SelectVoucher';
import { VoucherCenterProvider } from '../../shared/context/VoucherCenterContext';
import { cleanUserInfo } from '../../shared/utils/helpers/purefunctions';
import { DEBUG_MODE, IS_LIVE, USER_ID } from '../../shared/config/app';
import Form from './v4/Form';
import RedirectToLoaderAndLandingPage from '../../shared/components/RedirectToLoaderAndLandingPage';
import { message } from 'antd';

function App({ appAccess }) {
  const {
    ON_DEMAND,
    STANDARD,
    SHARED,
    ADDRESS_BOOK,
    MY_ACCOUNTS,
    ORDERS,
    PAYMENT,
    EXTRAS,
    ADDRESS,
  } = MODULE_PATH;

  const [showLoader, onSetShowLoader] = useState(true);

  const dispatch = useDispatch();

  console.log('DEBUG_MODE', DEBUG_MODE);

  const localAuthCode = USER_ID;
  const userType = useSelector(state => state.usersReducer.userOrderTags.userType);

  useEffect(() => {
    async function fetchAndUseAppInfo() {
      const appInfoDAO = new AppInfoDAO();
      const appInfo = await appInfoDAO.fetchAppInfo();
      if (appInfo) {
        dispatch(setAppInfo(appInfo));
        if (appInfo?.isAppUp) {
          miniProgram();
        } else {
          dispatch(setAppAccess(APP_ACCESS.MAINTENANCE));
        }
      }
    }
    fetchAndUseAppInfo();
  }, []);

  const login = async gcashInfo => {
    let getUsersInfo;
    let user;

    const loginDao = new Login();
    const { authCode } = gcashInfo;

    if (isEmpty(authCode)) {
      return dispatch(setAppAccess(APP_ACCESS.MAINTENANCE));
    }

    if (IS_LIVE) {
      getUsersInfo = await loginDao.getAuthCode(authCode);
    } else {
      getUsersInfo = await loginDao.getUsersInfo(authCode);
    }

    const { userInfo = {}, userFlags, userOrderTags } = getUsersInfo.result;

    const hasErrorUserInfo = USER_INFO_MESSAGE.error in userInfo;

    if (hasErrorUserInfo) {
      return dispatch(setAppAccess(APP_ACCESS.MAINTENANCE));
    }

    const currentUser = cleanUserInfo(IS_LIVE ? userInfo?.userId : authCode, userInfo);

    dispatch(setCurrentUser(currentUser));
    dispatch(setTags(userOrderTags));
    dispatch(setFlags(userFlags));

    return user;
  };

  const miniProgram = async () => {
    if (IS_LIVE) {
      await my.postMessage({ sendToMiniProgramFromWebApp: '1' });
      my.onMessage = async e => {
        if (!isEmpty(e?.code)) {
          await login(e?.code, e?.systemInfo);
          onSetShowLoader(false);

          setTimeout(async () => {
            dispatch(setAppAccess(APP_ACCESS.HOME));
          }, 3000);
        } else {
          dispatch(setAppAccess(APP_ACCESS.UNAUTHORIZED));
        }
      };
    } else {
      login({ authCode: localAuthCode });
      setTimeout(async () => {
        dispatch(setAppAccess(APP_ACCESS.HOME));
      }, 3000);
    }
  };

  return (
    <>
      <BrowserRouter forceRefresh={true}>
        <InputItemDescription>
          <InputItemRate>
            <InputItemType>
              <VoucherCenterProvider userType={userType}>
                <Routes>
                  <Route
                    path={MODULE_PATH.WELCOME}
                    element={
                      <RedirectToLoaderAndLandingPage
                        shouldRedirect={appAccess}
                        showLoader={showLoader}
                      />
                    }
                  />
                  <Route path={MODULE_PATH.HOME} element={<HomeV4 />} />
                  <Route path={ON_DEMAND.MAIN} element={<OnDemandDelivery />} />
                  <Route path={SHARED.VEHICLE} element={<VehicleTypes />} />
                  <Route path={SHARED.ITEM} element={<ItemDetailsList />} />
                  <Route path={SHARED.ON_DEMAND.COURIER} element={<CouriersList />} />
                  <Route path={STANDARD.ITEM} element={<ItemDetailsList />} />
                  <Route path={STANDARD.MAIN} element={<StandardDelivery />} />
                  <Route path={STANDARD.PRODUCT_LIST} element={<ProductList />} />
                  <Route path={SHARED.STANDARD.COURIER} element={<StandardCourierList />} />
                  <Route path={STANDARD.SENDER_DETAILS} element={<SenderDetails />} />
                  <Route path={STANDARD.SELECT_ADDRESS} element={<SelectAddress />} />
                  <Route path={STANDARD.ORDER_HISTORY} element={<OrderHistory />} />

                  <Route
                    path={ADDRESS.ON_DEMAND.SELECT_ADDRESS}
                    element={<SelectPickupAddress />}
                  />
                  <Route path={ADDRESS.ON_DEMAND.SEARCH_LOCATION} element={<SearchLocation />} />
                  <Route path={ADDRESS.ON_DEMAND.PICKUP_DETAILS} element={<PickupDetails />} />
                  <Route path={ADDRESS.ON_DEMAND.SAVED_PLACES_FORM} element={<SavedPlacesForm />} />

                  <Route path={ADDRESS.STANDARD.SELECT_ADDRESS} element={<SelectPickupAddress />} />
                  <Route path={ADDRESS.STANDARD.SEARCH_LOCATION} element={<SearchLocation />} />
                  <Route path={ADDRESS.STANDARD.PICKUP_DETAILS} element={<PickupDetails />} />
                  <Route path={ADDRESS.STANDARD.SAVED_PLACES_FORM} element={<SavedPlacesForm />} />

                  <Route path={PAYMENT.ON_DEMAND.MAIN} element={<Payment />} />
                  <Route path={PAYMENT.STANDARD.MAIN} element={<Payment />} />
                  <Route path={PAYMENT.ON_DEMAND.PAYMENT_SUMMARY} element={<PaymentSummary />} />
                  <Route path={PAYMENT.STANDARD.PAYMENT_SUMMARY} element={<PaymentSummary />} />

                  <Route path={PAYMENT.ON_DEMAND.PAYMENT_PROCESS} element={<PaymentProcess />} />
                  <Route path={PAYMENT.STANDARD.PAYMENT_PROCESS} element={<PaymentProcess />} />
                  <Route path={ADDRESS_BOOK.MAIN} element={<AddressBook />} />
                  <Route path={ADDRESS_BOOK.FORM} element={<AddressBookForm />} />
                  <Route path={ADDRESS_BOOK.MAP} element={<AddressBookPinMapLocation />} />
                  <Route path={MY_ACCOUNTS.MAIN} element={<MyAccounts />} />
                  <Route path={MY_ACCOUNTS.ACCOUNT_DETAILS} element={<AccountDetails />} />
                  <Route path={MY_ACCOUNTS.CASH_BACK} element={<CashBack />} />
                  <Route path={MY_ACCOUNTS.CONTACT_US} element={<ContactUs />} />
                  <Route path={MY_ACCOUNTS.ABOUT_US} element={<AboutUs />} />
                  <Route path={MY_ACCOUNTS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
                  <Route path={MY_ACCOUNTS.TERMS_CONDITIONS} element={<TermsAndConditions />} />
                  <Route path={MY_ACCOUNTS.REFERRALS} element={<Referrals />} />

                  <Route path={ORDERS.VIEW_ALL_ORDERS} element={<ViewAllOrders />} />
                  <Route path={ORDERS.PAYMENT_BREAKDOWN} element={<PaymentBreakdown />} />
                  <Route path={ORDERS.ORDER_DETAILS} element={<OrderDetails />} />
                  <Route path={ORDERS.CANCEL_ORDER} element={<CancelOrder />} />
                  <Route path={ORDERS.ORDER_CANCELLED} element={<OrderCancelled />} />
                  <Route path={ORDERS.SEARCH_ORDER} element={<SearchOders />} />
                  <Route path={ORDERS.FAILED_ORDER} element={<FailedOrder />} />
                  <Route path={EXTRAS.LOADER} element={<Loader />} />
                  <Route path={EXTRAS.ERROR} element={<Error />} />
                  <Route path={EXTRAS.HELP} element={<Help />} />
                  <Route path={EXTRAS.HELP_CENTER} element={<HelpCenter />} />
                  <Route path={EXTRAS.COMING_SOON} element={<ComingSoon />} />
                  <Route path={EXTRAS.COMING_SOON_IOS} element={<ComingSoonIOS />} />
                  <Route path={EXTRAS.MAINTENANCE} element={<MaintenancePage />} />
                  <Route path={EXTRAS.UNAUTHORIZED} element={<Unauthorized />} />
                  <Route path={EXTRAS.SEARCH_NEARBY_RIDERS} element={<SearchNearbyRiders />} />
                  <Route path={EXTRAS.REFUND_ERROR} element={<RefundError />} />
                  <Route path={EXTRAS.TRACK_ORDER_MAP} element={<ProviderMaps />} />
                  <Route path={EXTRAS.VOUCHER_CENTER} element={<VoucherCenter />} />
                  <Route path={EXTRAS.VOUCHER_TC} element={<VoucherTCs />} />
                  <Route path={EXTRAS.SELECT_VOUCHER} element={<SelectVoucher />} />
                  <Route path="/home-v3" element={<HomeV3 />} />

                  {/* v4 */}
                  <Route path={MODULE_PATH.ON_DEMAND.FORM} element={<Form />} />
                  <Route path={MODULE_PATH.STANDARD.FORM} element={<Form />} />
                </Routes>
              </VoucherCenterProvider>
            </InputItemType>
          </InputItemRate>
        </InputItemDescription>
      </BrowserRouter>
    </>
  );
}

function mapStateToProps(state) {
  return {
    loader: state.homeReducer.loader,
    appAccess: state.appAccessReducer.appAccess,
  };
}

export default connect(mapStateToProps, null)(App);
