import React, { useEffect, useState } from 'react';
import { ICON } from '../utils/enums/AppIcons';
import {
  calculateCashbackDeduction,
  calculateFinalShippingfee,
  formatBills,
  isEmpty,
} from '../utils/helpers/purefunctions';
import { Switch } from 'antd';
import CashbackHelp from '../../app/body/OndemandDelivery/elements/CashbackHelp';
import { useDispatch, useSelector } from 'react-redux';

import { setCashbackPaymentDetails } from '../redux/onDemand/actions';

const PaymentDetails = ({
  totalAmount,
  shippingFee,
  convenienceFee,
  tip,
  voucherAmount,
  cashBack,
  useCashBack,
  handleCashBackSwitch,
  isPaymentBreakdownPage,
  referralTag,
}) => {
  const dispatch = useDispatch();
  const [showHelp, setShowHelp] = useState(false);

  const cashbackTransactions = useSelector(state => state.homeReducer.cashbackTransactions);
  const formTab = useSelector(state => state.homeReducer.formTab);

  const isStandardDelivery = formTab.toLowerCase().includes('standard');

  const fromLess = cashbackTransactions.filter(item => item.cashbackFrom === 'less');
  const lessAmount = fromLess.reduce((acc, obj) => acc + obj.amount, 0);

  const fromTipAndBroadcast = cashbackTransactions.filter(item => item.cashbackFrom !== 'less');
  const tipAndBroadcastAmount = fromTipAndBroadcast.reduce((acc, obj) => acc + obj.amount, 0);

  const cashbackBalance =
    Number(tipAndBroadcastAmount) <= Number(lessAmount) ? 0 : tipAndBroadcastAmount - lessAmount;

  const deductedAmount = useCashBack ? calculateCashbackDeduction((shippingFee - voucherAmount), cashbackBalance) : 0;

  const cashbackDeduction = useCashBack ? `P -${formatBills(deductedAmount)}` : 0;

  const shippingFeeAfterDeduction = calculateFinalShippingfee(shippingFee, cashbackBalance);

  const totalAmountAfterCashback = useCashBack
    ? convenienceFee + shippingFeeAfterDeduction
    : totalAmount;

  const redeemCashbackBalance = Number(cashbackBalance) > 0 ? cashbackBalance : cashBack;

  const finalShippingFee = useCashBack ? shippingFeeAfterDeduction : shippingFee;

  useEffect(() => {
    if (useCashBack) {
      dispatch(
        setCashbackPaymentDetails({
          finalShippingFee,
          cashbackDeduction: deductedAmount,
          paymentAmount: totalAmountAfterCashback,
          isCashback: useCashBack,
        })
      );
    } else {
      dispatch(
        setCashbackPaymentDetails({
          finalShippingFee: 0,
          cashbackDeduction: 0,
          paymentAmount: 0,
          isCashback: false,
        })
      );
    }
  }, [useCashBack]);

  return (
    <div className="payment-details-container">
      <div className="column-1 lbl-large">Payment Details</div>
      <div className="column-2">
        <div className="shipping-fee">
          <span>Shipping Fee Subtotal</span>
          <span className="mlLbl-small">{`P ${formatBills(Number(finalShippingFee))}`}</span>
        </div>
        {useCashBack && (
          <div className="cashback-deduction cntnt-caption">
            Cashback subtotal <span>- {formatBills(deductedAmount)}</span>
          </div>
        )}
        <div className="convinience-fee">
          <span className="convenience-fee-left cntnt-caption-bold">
            <span>Convenience Fee</span>
          </span>
          <span className="mlLbl-small">{`P ${formatBills(convenienceFee)}`}</span>
        </div>
        {!isEmpty(Number(tip)) && (
          <div className="drivers-tip">
            <span>Driver's Tip</span>
            <span>{`P ${formatBills(tip)}`}</span>
          </div>
        )}
        {!isEmpty(Number(voucherAmount)) && (
          <div className={`voucher-discount`}>
            <span className="voucher-discount-left">
              <img src={ICON.VOUCHER_ICON} alt="voucher-icon" />
              Voucher Code
            </span>
            <span className="voucher-discount-right">{`P -${formatBills(voucherAmount)}`}</span>
          </div>
        )}
        {!isStandardDelivery && !isEmpty(cashbackTransactions) && (
          <div className="cashback">
            <span className="mlLbl-small">
              <img src={ICON.CASHBACK_ICON} alt="cashback icon" />
              Cashback
              <img
                src={ICON.HELP_GRAY}
                className="help"
                onClick={() => setShowHelp(true)}
                alt="help-icon"
              />
            </span>
            {!isPaymentBreakdownPage ? (
              <span className={`cashback-amount ${useCashBack ? 'checked' : 'unchecked'}`}>
                {useCashBack ? cashbackDeduction : `Redeem P ${formatBills(redeemCashbackBalance)}`}
                <Switch
                  size={'small'}
                  onChange={e => handleCashBackSwitch(e)}
                  className={`cashback-switch ${useCashBack ? 'checked' : 'unchecked'}`}
                />
              </span>
            ) : (
              <span className="cashback-amount checked">{`P -${formatBills(cashBack)}`}</span>
            )}
          </div>
        )}
      </div>
      <div className="column-3">
        <div className="total">
          <span className="cntnt-base-bold">Total Amount</span>
          <span className="hdln-segment">{`P ${formatBills(totalAmountAfterCashback)}`}</span>
        </div>
      </div>
      <CashbackHelp show={showHelp} onSetShow={() => setShowHelp(false)} />
    </div>
  );
};

export default PaymentDetails;
