import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { message, Modal } from 'antd';
import DPHDAO from '../../../shared/utils/dao/DPH';
import { TRADE_PAY } from '../../../shared/utils/enums/GCashResponse';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import {
  setPaymentRequestId,
  setCourier,
  setOndemandCashBackAmount,
  setCurrentCourierRates,
} from '../../../shared/redux/onDemand/actions';
import {
  setStandardPaymentRequestId,
  setStandardCashBackAmount,
} from '../../../shared/redux/standard/actions';
import { setLoader } from '../../../shared/redux/home/actions';
import {
  createOrderPayload,
  formatBills,
  formatPaymentAmount,
  formatPostOrder,
  getConvenienceFee,
  getHighestRate,
} from '../../../shared/utils/helpers/purefunctions';

import { COURIERS, PERCENT_BASE_FEE } from '../../../shared/utils/enums/DeliveryConstants';

import { onDemandPayload, scheduledPayload } from '../../../shared/utils/helpers/Payload';
import PaymentSummaryV2 from '../../../shared/components/v2/PaymentSummary';
import { BOOK_ANY, DELIVERY_TYPE_VIEW } from '../../../shared/utils/enums/AppConstants';
import { MODULE_PATH } from '../../../shared/constants/Module';
import { API, API_CONFIG, IS_LIVE, DEBUG_MODE } from '../../../shared/config/app';

const confirm = Modal.confirm;

const PaymentSummary = ({
  onDemandcourier,
  standardcourier,
  vehicleType,
  setLoader,
  onDemandProps,
  standardProps,
  userInfo,
  onDemandPaymentRequestId,
  standardPaymentRequestId,
  setCourier,
  setOndemandCashBackAmount,
  setStandardCashBackAmount,
  setCurrentCourierRates,
  setup,
}) => {
  let navigate = useNavigate();

  const [requestId, setRequestId] = useState('');
  const [cashBack, setCashBack] = useState(0);
  const [switchCount, setSwitchCount] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  // const [voucherAmount, setVoucherAmount] = useState(0);
  const [intervalFunction, setIntervalFunction] = useState(null);
  const [useCashBack, setUseCashBack] = useState(false);
  const [isPaymentCliked, setIsPaymentClicked] = useState(false);

  const { formTab } = useSelector(state => state.homeReducer);

  const isStandard = formTab.toLowerCase().includes('standard');

  const courier = isStandard ? standardcourier : onDemandcourier;
  const isBookAny = courier.courier === BOOK_ANY;
  const highestCourierRate = getHighestRate(onDemandProps.courierRates);

  const cashBackAmount = useCashBack && cashBack;
  const convenienceFee = isBookAny ? getConvenienceFee().BROADCAST : getConvenienceFee().REGULAR;

  const standardShippingFee = standardcourier.rate;
  const onDemandShippingFee =
    isBookAny && onDemandcourier.rate < highestCourierRate
      ? highestCourierRate
      : onDemandcourier.rate;

  const shippingFee = isStandard ? Number(standardShippingFee) : onDemandShippingFee;
  const refNo = isStandard ? standardProps?.orderId : onDemandProps?.orderId;
  const voucherAmount = onDemandProps.voucherAmount;
  const { cashbackDeduction } = onDemandProps.cashbackPaymentDetails;

  const discountFees = Number(voucherAmount) + Number(cashBackAmount);
  const rate = formatBills(shippingFee - discountFees);

  const paymentProcessPath = formTab.toLowerCase().includes('ondemand')
    ? MODULE_PATH.PAYMENT.ON_DEMAND.PAYMENT_PROCESS
    : MODULE_PATH.PAYMENT.STANDARD.PAYMENT_PROCESS;

  useEffect(() => {
    setLoader(false);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalFunction);
    };
  }, [intervalFunction]);

  const failedGcashPaymentInquiry = (userId, paymentRequestId, res) => {
    const PaymentDao = new PaymentDAO();
    PaymentDao.gcashPaymentInquiry({
      userId,
      paymentRequestId,
    });
  };

  const payGCash = async amount => {
    setLoader(false);
    await proceedPayWithGCash(amount);
  };

  const payWithGCash = async amount => {
    setLoader(true);

    if (isStandard) {
      const scheduledQoutePayload = scheduledPayload(standardProps, userInfo);
      const standard = await DPHDAO.getAvailableScheduledPartner(scheduledQoutePayload);

      if (standard) {
        const filteredStandardCourier = standard.filterCouriers.filter(
          data => data.id === standardcourier.courier
        );

        const [productInfo] = filteredStandardCourier
          .map(data =>
            data.products.filter(
              productDetails => productDetails.size === standardProps.product.value.charAt(0)
            )
          )
          .flat();

        if (filteredStandardCourier.length) {
          if (productInfo.price !== standardcourier.rate) {
            const newRate = productInfo.price;

            setLoader(false);

            confirm({
              className: 'discard-order',
              okText: 'Yes',
              cancelText: 'No',
              content: `Courier rate has changed, from P ${formatBills(
                standardcourier.rate
              )} to P ${formatBills(newRate)}. Would you like to proceed?`,
              onOk: async () => {
                setCourier({ ...standardcourier, rate: newRate });
              },
              onCancel: () => {},
            });
          } else {
            await payGCash(amount);
          }
        } else {
          await payGCash(amount);
        }
      } else {
        setLoader(false);
        return message.error('Please retry payment.', 3.0);
      }
    } else {
      let onDemand;
      let currentCourierRates = {};
      let highestRatedCourier = {};

      const onDemandQoutePayload = onDemandPayload(
        onDemandProps,
        [onDemandcourier.courier.toUpperCase()],
        userInfo
      );

      if (isBookAny) {
        onDemand = await DPHDAO.getOnDemandQuotation(
          onDemandPayload(onDemandProps, COURIERS, userInfo)
        );

        currentCourierRates = onDemand.reduce((rates, { courier, rate }) => {
          rates[courier] = rate;
          return rates;
        }, {});

        highestRatedCourier = onDemand.reduce(
          (maxRate, obj) => (obj.rate > maxRate.rate ? obj : maxRate),
          onDemand[0]
        );
      } else {
        onDemand = await DPHDAO.getOnDemandQuotation(onDemandQoutePayload);
      }

      if (onDemand) {
        const currentOndemandCourier = isBookAny
          ? highestRatedCourier.courier
          : onDemandcourier.courier;

        const getQuote = onDemand.filter(quote => quote.courier === currentOndemandCourier);

        if (getQuote.length) {
          if (getQuote[0].rate !== onDemandcourier.rate) {
            const newRate = Number(getQuote[0].rate || 0);
            setLoader(false);
            confirm({
              className: 'discard-order',
              okText: 'Yes',
              cancelText: 'No',
              content: `${
                isBookAny ? 'Book Any' : 'Courier'
              } rate has changed, from P ${formatBills(onDemandcourier.rate)} to P ${formatBills(
                newRate
              )}. Would you like to proceed?`,
              onOk: async () => {
                setCourier({ ...onDemandcourier, rate: newRate });
                setCurrentCourierRates(currentCourierRates);
              },
              onCancel: () => {},
            });
          } else {
            await payGCash(amount);
          }
        } else {
          await payGCash(amount);
        }
      } else {
        setLoader(false);
        return message.error('Please retry payment.', 3.0);
      }
    }
  };

  function paymentInquiryInterval(userId, createdPaymentRequestId) {
    const setIntervalId = setInterval(() => {
      failedGcashPaymentInquiry(userId, createdPaymentRequestId);
    }, 60 * 1000);
    setIntervalFunction(setIntervalId);
  }

  function myTradePay(
    paymentUrl,
    createdPaymentRequestId,
    createdPaymentId,
    paymentAmount,
    paymentAPI
  ) {
    my.tradePay({
      paymentUrl,
      success: function (res) {
        if (['9000'].includes(String(res?.resultCode))) {
          return navigate(paymentProcessPath, {
            state: {
              gcash: {
                paymentRequestId: createdPaymentRequestId,
                paymentId: createdPaymentId,
                paymentAmount,
              },
              refNo: paymentAPI.refNo,
              useCashBack: useCashBack,
            },
          });
        } else {
          setLoader(false);
          setIsPaymentClicked(false);
          failedGcashPaymentInquiry(userInfo.currentUser.id, createdPaymentRequestId);
        }
        setLoader(false);
        setIsPaymentClicked(false);
      },
      fail: function (res) {
        setLoader(false);
        setIsPaymentClicked(false);
        failedGcashPaymentInquiry(userInfo.currentUser.id, createdPaymentRequestId);
        message.error(
          TRADE_PAY[res?.resultCode] || `Failed: Processing Error ${res?.resultCode || ''}`,
          3.0
        );
      },
    });
  }

  const proceedPayWithGCash = async amount => {
    const userPayment = useCashBack
      ? parseFloat(onDemandProps?.cashbackPaymentDetails.paymentAmount).toFixed(2)
      : parseFloat(parseFloat(rate) + parseFloat(amount)).toFixed(2);

    const postOrder = formatPostOrder(onDemandProps, standardProps, isStandard, userInfo);
    const activeOrderType = isStandard ? standardProps : onDemandProps;

    const { orderPayload, courierPayload } = createOrderPayload(postOrder, userInfo);
    const { orderId = '', itemType = '', itemDescription = '' } = activeOrderType || {};
    const paymentAmount = formatPaymentAmount(userPayment, voucherAmount);
    const userId = userInfo?.currentUser?.id;

    const paymentAPI = {
      userId,
      paymentAmount,
      paymentRequestId: requestId,
      refNo: orderId,
      paymentOrderTitle: itemType || itemDescription,
      order: { ...orderPayload, courier: courierPayload },
    };

    if (IS_LIVE) {
      /* call payments/pay via parcelsIO first to get the paymentRequestId and payment status */
      const paymentDao = new PaymentDAO();
      const paidBooking = await paymentDao.gcashPayment(paymentAPI);

      if (paidBooking.success) {
        /* create booking order first so once notify url response, booking order exist */
        const createdPaymentRequestId = paidBooking.result.paymentRequestId;
        const createdPaymentId = paidBooking.result.paymentId;
        const paymentUrl = paidBooking.result.actionForm.redirectionUrl;

        paymentInquiryInterval(userId, createdPaymentRequestId);
        myTradePay(
          paymentUrl,
          createdPaymentRequestId,
          createdPaymentId,
          paymentAmount,
          paymentAPI
        );
      } else {
        // message.info(`Failed: ${paidBooking.message}`, 3.0);
      }
    } else {
      return navigate(paymentProcessPath, {
        state: {
          gcash: {
            paymentRequestId: `TEST-${paymentAPI.paymentRequestId}`,
            paymentId: `TEST-${paymentAPI.paymentRequestId}`,
            paymentAmount,
          },
          refNo: paymentAPI.refNo,
          useCashBack: useCashBack,
        },
      });
    }
  };

  const handleCashBackSwitch = boolean => {
    const cashbackAmount = boolean ? cashBack : 0;
    const setActiveOrderTypeCashbackAmount = isStandard
      ? setStandardCashBackAmount
      : setOndemandCashBackAmount;

    setUseCashBack(boolean);
    setSwitchCount(switchCount + 1);
    setActiveOrderTypeCashbackAmount(cashbackAmount);
  };

  const handlePayWithGCash = amount => {
    if (DEBUG_MODE) return navigate(paymentProcessPath);
    payWithGCash(amount);
  };

  return (
    <PaymentSummaryV2
      setCashBack={setCashBack}
      setUseCashBack={setUseCashBack}
      useCashBack={useCashBack}
      cashBack={cashBack}
      handleCashBackSwitch={handleCashBackSwitch}
      voucherAmount={voucherAmount}
      cashBackAmount={cashBackAmount}
      discountFees={discountFees}
      shippingFee={shippingFee}
      rate={rate}
      convenienceFee={convenienceFee}
      payWithGCash={amount => handlePayWithGCash(amount)}
      isPaymentCliked={isPaymentCliked}
      setIsPaymentClicked={bool => setIsPaymentClicked(bool)}
    />
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
      setOnDemandPaymentRequestId: setPaymentRequestId,
      setStandardPaymentRequestId,
      setCourier,
      setOndemandCashBackAmount,
      setStandardCashBackAmount,
      setCurrentCourierRates,
    },
    dispatch
  );
}

const mapStateToProps = state => ({
  onDemandcourier: state.onDemandReducer.courier,
  standardcourier: state.standardReducer.courier,
  vehicleType: state.onDemandReducer.vehicleType,
  onDemandPaymentRequestId: state.onDemandReducer.paymentRequestId,
  standardPaymentRequestId: state.standardReducer.paymentRequestId,
  standardItemPrice: state.standardReducer.itemPrice,
  onDemandProps: state.onDemandReducer,
  standardProps: state.standardReducer,
  userInfo: state.usersReducer,
  setup: state.appAccessReducer.setup,
});
export default connect(mapStateToProps, matchDispatchToProps)(PaymentSummary);
