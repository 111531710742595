import { MERCHANT_ID as MERCHANT } from '../utils/enums/AppConstants';
import { PROD_USERS, STAGING_USERS } from '../utils/enums/TestUsers';

export const API = {
  STAGING: {
    EMAIL: 'gcash@parcels.ph',
    PASSWORD: 'l5w2hnsi7',
    DPH_API: 'https://us-central1-fleet-staging-2333a.cloudfunctions.net',
    DPH_SERVICE_MATRIX: 'https://solutions.fleet.ph:9016',
    DPH_SERVICE_MATRIX_AUTH: '5C7E17C3698FC6767603D93ABE8540B3FA337ACADD13552081B96A687B552EC4',
    PARCELS_API: 'https://staging-api.myparcels.ph',
    PARCELS_AUTH: '163e3ebd6ea01de274904864e42e2776',
    PARCELS_GET_AUTH: '02c5b2498d32e983b943c6d7260b1008',
    ENV: 'STAGING',
  },
  PRE_PRODUCTION: {
    EMAIL: 'gcash@parcels.ph',
    PASSWORD: 'qwzo6gd2n',
    DPH_API: 'https://us-central1-fleet-ph.cloudfunctions.net',
    DPH_SERVICE_MATRIX: 'https://solutions.fleet.ph:9017',
    DPH_SERVICE_MATRIX_AUTH: '3DB764C916E26CEBA17B6D5D1F9A0BF0C3B3F7E36DBB3DC330DC35E2D4B17C2B',
    PARCELS_API: 'https://preprod-api.myparcels.ph',
    PARCELS_AUTH: '163e3ebd6ea01de274904864e42e2776',
    PARCELS_GET_AUTH: '02c5b2498d32e983b943c6d7260b1008',
    ENV: 'PRE-PRODUCTION',
  },
  PRODUCTION: {
    EMAIL: 'gcash@parcels.ph',
    PASSWORD: 'qwzo6gd2n',
    DPH_API: 'https://us-central1-fleet-ph.cloudfunctions.net',
    DPH_SERVICE_MATRIX: 'https://solutions.fleet.ph:9017',
    DPH_SERVICE_MATRIX_AUTH: '3DB764C916E26CEBA17B6D5D1F9A0BF0C3B3F7E36DBB3DC330DC35E2D4B17C2B',
    PARCELS_API: 'https://api.myparcels.ph',
    PARCELS_AUTH: '163e3ebd6ea01de274904864e42e2776',
    PARCELS_GET_AUTH: '02c5b2498d32e983b943c6d7260b1008',
    ENV: 'PRODUCTION',
  },
};

export const FIREBASE = {
  STAGING: {
    apiKey: 'AIzaSyDpTZzlJO5kw2Xg2WSEKwneKhNxQDGTdMY',
    authDomain: 'fleet-staging-2333a.firebaseapp.com',
    databaseURL: 'https://fleet-staging-2333a.firebaseio.com',
    projectId: 'fleet-staging-2333a',
    storageBucket: 'fleet-staging-2333a.appspot.com',
    messagingSenderId: '300017822442',
    appId: '1:300017822442:web:91212cf246daaa74880593',
    measurementId: 'G-RW7DWZ5NG3',
  },
  PRE_PRODUCTION: {
    apiKey: 'AIzaSyC_n8J4XuNGtgoxhB0f73iJ4DJvK7D0XhY',
    authDomain: 'fleet-ph.firebaseio.com',
    databaseURL: 'https://fleet-ph.firebaseio.com',
    projectId: 'fleet-ph',
    storageBucket: 'fleet-ph.appspot.com',
    messagingSenderId: '965150477781',
    appId: '1:300017822442:web:91212cf246daaa74880593',
  },
  PRODUCTION: {
    apiKey: 'AIzaSyC_n8J4XuNGtgoxhB0f73iJ4DJvK7D0XhY',
    authDomain: 'fleet-ph.firebaseio.com',
    databaseURL: 'https://fleet-ph.firebaseio.com',
    projectId: 'fleet-ph',
    storageBucket: 'fleet-ph.appspot.com',
    messagingSenderId: '965150477781',
    appId: '1:300017822442:web:91212cf246daaa74880593',
  },
};

/* set to true if for deployment */
export const IS_LIVE = true;

/* set PROD_USERS for prod env and STAGING_USERS for staging env. */
export const USER_ID = PROD_USERS.MARY_GRACE.id;

/* set env of marchant id for google analytics reports */
export const MERCHANT_ID = MERCHANT.PRE_PRODUCTION;

/* set APIs env */
export const API_CONFIG = API.PRE_PRODUCTION;

/* set firebase config env */
export const FIREBASE_CONFIG = FIREBASE.PRE_PRODUCTION;

/* toast debugger */
export const SHOW_DEBUG_TOAST = IS_LIVE && API_CONFIG.ENV === 'STAGING';

/* Debug mode */
export const DEBUG_MODE = !IS_LIVE && API_CONFIG.ENV === 'STAGING';
