import React from 'react';
import Vouchers from './Vouchers';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import FooterBtn from '../../../../../shared/components/FooterBtn';
import { getAmountOff } from '../../../../../shared/utils/helpers/purefunctions';
import moment from 'moment-timezone';
import { DD_MMM_YYYY, DD_MMM_YYYY_HH_MM } from '../../../../../shared/utils/enums/dateFormats';
import {
  setOndemandReState,
} from '../../../../../shared/redux/onDemand/actions';

const VoucherTCs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const prevNavigate = {
    label: 'Voucher T&Cs',
    imgPath: '',
    path: location?.state?.link || '/',
  };

  const voucherData = location?.state?.voucherData;
  
    const handleBackSetCode = () => {
      dispatch(setOndemandReState(onDemandProps))
    }

  const amountOff = getAmountOff(voucherData);
  return (
    <div className="voucher__tc__container">
      <StickyHeader title="Voucher T&Cs" onPrevNavigate={prevNavigate} callback={() => handleBackSetCode() }  />
      <div className="content flex-col gap-16px">
        <Vouchers
          from="voucher-tc"
          logo={ICON.PINK_PARCELS_LOGO}
          voucherIcon={ICON.VOUCHER_CARD_ICON_GREEN}
          amountOff={amountOff}
        />
        <div className="remind-me flex-row justify-start items-center gap-8px">
          <img src={ICON.PINK_CLOCK} alt="Clock" />
          <div>Use from: {moment(voucherData.startDate).format(DD_MMM_YYYY_HH_MM)}</div>
        </div>
        <div className="description-tc flex-col justify-start gap-24px">
          <div className="valid-period flex-col gap-8px">
            <div>Valid Period</div>
            <div>
              {`${moment(voucherData.startDate).format(DD_MMM_YYYY_HH_MM)} - ${moment(
                voucherData.expiration
              ).format(DD_MMM_YYYY_HH_MM)}`}{' '}
            </div>
          </div>
          <div className="rewards flex-col gap-8px">
            <div>Rewards</div>
            <div>• {getAmountOff(voucherData)} on your first booking</div>
          </div>
          <div className="applications flex-col gap-8px">
            <div>Applications</div>
            <div>
              <span>• Valid for on-demand couriers only.</span>
              <span>• For first booking only.</span>
            </div>
          </div>
          <div className="payments flex-col gap-8px">
            <div>Payments</div>
            <div>• GCash Wallet</div>
          </div>
          <div className="logistics flex-col gap-8px">
            <div>Logistics</div>
            <div>• On-Demand Couriers Only</div>
          </div>
          <div className="more-details flex-col gap-8px">
            <div>More Details</div>
            <div className="flex-col gap-8px">
              <span>1. Valid on November 1 to 30, 2024</span>
              <span>1. One time use only</span>
            </div>
          </div>
        </div>
        <FooterBtn
          className="voucher-tc"
          childClass="radius"
          label="Okay"
          onClick={() => navigate(prevNavigate.path)}
        />
      </div>
    </div>
  );
};

export default VoucherTCs;
