import { useEffect, useState } from 'react';
import DPHDAO from '../utils/dao/DPH';
import { filterCourierPartner } from '../utils/helpers/purefunctions';
import { COURIERS } from '../utils/enums/DeliveryConstants';
import { onDemandPayload } from '../utils/helpers/Payload';

const useOnDemandCouriers = (
  onDemandProps,
  userInfo,
  isOndemand,
  hasAddress,
  isFromItemDetails,
  autoFetch = true // Default: auto fetch on mount
) => {
  const [onDemandCouriers, setOnDemandCouriers] = useState([]);
  const [isLoadingOnDemandCouriersQoute, setIsLoadingOnDemandCouriersQoute] = useState(false);
  const [errorOnDemandCouriersQoute, setErrorOnDemandCouriersQoute] = useState(null);

  const fetchOnDemandCouriers = async () => {
    if (!(isOndemand && hasAddress && isFromItemDetails)) {
      return;
    }

    let isSubscribed = true;
    try {
      setIsLoadingOnDemandCouriersQoute(true);
      setErrorOnDemandCouriersQoute(null);

      const couriersPartnersData = await DPHDAO.getPartners();
      if (!isSubscribed) return;

      const filteredPartners = filterCourierPartner(couriersPartnersData, COURIERS);
      const qoutationPayload = onDemandPayload(onDemandProps, filteredPartners, userInfo);
      const onDemandQouteAPI = await DPHDAO.getOnDemandQuotation(qoutationPayload);

      if (!isSubscribed) return;
      setOnDemandCouriers(onDemandQouteAPI);
    } catch (error) {
      if (!isSubscribed) return;
      setErrorOnDemandCouriersQoute(
        error instanceof Error ? error : new Error('An unknown error occurred')
      );
    } finally {
      if (isSubscribed) {
        setIsLoadingOnDemandCouriersQoute(false);
      }
    }

    return () => {
      isSubscribed = false;
    };
  };

  // Automatically fetch on mount if autoFetch is true
  useEffect(() => {
    let isSubscribed = true;

    if (autoFetch) {
      fetchOnDemandCouriers();
    }

    return () => {
      isSubscribed = false;
    };
  }, [hasAddress, onDemandProps, userInfo, autoFetch]);

  return {
    onDemandCouriers,
    isLoadingOnDemandCouriersQoute,
    errorOnDemandCouriersQoute,
    fetchOnDemandCouriers, // Expose function for manual fetch
  };
};

export default useOnDemandCouriers;
