import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MODULE_PATH } from '../../../../../shared/constants/Module';

import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import {
  setPointLocation as setOnDemandPointLocation,
  setLocation as setOndemandLocation,
} from '../../../../../shared/redux/onDemand/actions';
import {
  setPointLocation as setStandardPointLocation,
} from '../../../../../shared/redux/standard/actions';
import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';

const DeliveryTo = ({ orders }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [slicedRecords, setSlicedRecords] = useState([]);

  const { formTab } = useSelector(state => state.homeReducer);
  const isOndemand = formTab.toLowerCase().includes('ondemand');
  const setPointLocationPath = isOndemand ? setOnDemandPointLocation : setStandardPointLocation;

  useEffect(() => {
    if (!isEmpty(orders)) {
      const sliced = orders.slice(0, 3);
      setSlicedRecords(sliced);
    }
  }, [orders]);


  const onClickCurrentAddress = (currentAddress) => {
    dispatch(setPointLocationPath('DropOff'));
    navigate(MODULE_PATH.ADDRESS.ON_DEMAND.PICKUP_DETAILS, {
      state: { showAutoComplete: true, isFromCurrentAddress: true, fromHome: true, ...location.state },
    });
    dispatch(setOndemandLocation(currentAddress));
  };

  return (
    <div className="delivery-to-container flex-row gap-16px">
      {slicedRecords.map((data, index) => (
        <div key={index} className="flex-row gap-16px items-center delivery-to" onClick={() => onClickCurrentAddress(data?.deliveryDetails?.deliveryAddress) }>
          <img src={ICON.LOCATION} alt="Location" style={{ width: '24px', marginBottom: '15px' }} />
          <div className="flex-col gap-8px">
            <span className='cntnt-caption'>{data?.deliveryDetails?.customerName}</span>
            <span className='lbl-base'>{data?.deliveryDetails?.deliveryAddress}</span>
          </div>
        </div>
      ))}
      <div className='orders__card__spacer'>&nbsp;</div>
    </div>
  );
};

export default DeliveryTo;
