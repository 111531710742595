import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { REFERRAL_DATE_FORMAT } from '../../../../../shared/utils/enums/dateFormats';
import { isEmpty, copyToClipBoard } from '../../../../../shared/utils/helpers/purefunctions';

import { V4_ICONS } from '../../../../../shared/utils/enums/AppIconsV4';

import { Typography } from 'antd';

const { Text } = Typography;

const Referrals = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const fullText = `
    a. User who will use the referral code will receive a P25.00 cashback credit upon booking completion.\n
    b. The Referrer will also get a P25.00 cashback credit if the User completes the booking.\n
    c. The P25.00 cashback can be used for future bookings.\n
    d. The referral code is for one-time use only.
  `;
  const truncatedText = fullText.substring(0, 100) + '...';

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const { referralCode, referralOffer, referRedemptions } = useSelector(
    state => state.referralReducer
  );
  const prevNavigate = {
    label: 'Terms and Conditions',
    imgPath: '',
    path: MODULE_PATH.MY_ACCOUNTS.MAIN,
  };

  const ReferralRedemptionsList = () => {
    return (
      <>
        {referRedemptions.map((data, index) => {
          const { codeUser, lastTimeStamp, amount } = data;
          return (
            <div className="redemptor">
              <div className="redemptor-info">
                <span className="mlLbl-small">{codeUser}</span>
                <span className="cntnt-fine-caption">
                  {moment(lastTimeStamp).format(REFERRAL_DATE_FORMAT)}
                </span>
              </div>
              <div className="referrer-offer">
                <span className="type cntnt-small-caption-bold">Earned</span>
                <span className="amount mlLbl-small">
                  <span>+</span> <span>₱ {amount.toFixed(2)}</span>
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="referrals-container">
      <div className="referrals-container-content">
        <StickyHeader title="Referrals" onPrevNavigate={prevNavigate} />
        <div className="body">
          <div className="referral-tags">
            <img src={V4_ICONS.BANNER} />
            <span className="banner-tagline hdln-segment">
              Get cashback points for you and your friends every time you share!
            </span>
          </div>
          <div className="body-content">
            <div className="body-title hdln-segment">Share and get rewards</div>

            <div className="card-rows">
              <div className="referral-code-amount">
                <img src={V4_ICONS.STAR} />
                <span className="amount">
                  <span className="title hdln-segment">₱ {Number(referralOffer).toFixed(2)}</span>
                  <span className="description cntnt-base">Share and earn cashback today</span>
                </span>
              </div>
            </div>

            <div className="card-rows">
              <div className="referral-code-wrapper" onClick={() => copyToClipBoard(referralCode)}>
                <span className="code">
                  <span className="title cntnt-caption">Referral code</span>
                  <span className="description cntnt-referral">{referralCode}</span>
                </span>
                <span className="copy">
                  <span className="title cntnt-caption">Copy</span>
                  <img src={V4_ICONS.COPY} />
                </span>
              </div>
            </div>

            <div className="card-rows">
              <div className="referral-code-tnc">
                <span className="title mlLbl-base">Terms and Conditions</span>
                <span className="read-more cntnt-caption">
                  {isExpanded
                    ? fullText.split('\n').map(
                        (line, index) =>
                          index > 0 && (
                            <span key={index}>
                              {line.trim()}
                              <br />
                            </span>
                          )
                      )
                    : truncatedText}{' '}
                  <Text style={{ color: '#1890ff', cursor: 'pointer' }} onClick={toggleReadMore}>
                    <span className="cntnt-base">{isExpanded ? 'Read Less' : 'Read More'}</span>
                  </Text>
                </span>
              </div>
            </div>

            <div className="card-rows-redemption">
              <div className="redemption-wrapper">
                <div className="title">
                  <img src={V4_ICONS.TROPHY} />
                  <span className="cntnt-large-bold">Successful Referrals</span>
                </div>
              </div>
              <div className="success-referral">
                {isEmpty(referRedemptions) ? (
                  <div style={{ margin: '0 auto', color: '#0a2757' }}>
                    No successful referrals available.
                  </div>
                ) : (
                  <ReferralRedemptionsList />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Referrals;
